import React from "react";

const ProductSection = () => {
  return (
    <>
      <div className="parentProductsection">
        <div className="row productsection">
          <div className="col colItemChange2 text-center">
            <h1>
              <span>Bulk Orders On DAQI Ordering Platform</span>
            </h1>
            <p
              style={{ color: "#FC5952", fontWeight: "600", fontSize: "17px" }}
            >
              Enhance Your Sales And Inventory With Daqi
            </p>
          </div>
        </div>
        <div className="container" style={{ marginTop: "75px" }}>
          <div className="row">
            <div className="col-md-3 col-lg-3 py-4 px-2 everycard">
              <div className="card border-0 cardClass">
                <img
                  className="card-image"
                  src="assets/img/credit_page/product_images/pvc_pipes2.png"
                  alt="prices"
                />
                <div className="card-body card-content-body">
                  <div>
                    <h6 className="card-title">PVC conduit pipes</h6>
                    <p className="card-text">
                      Instant credit limit upto Rs. 10 lacs
                    </p>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <button className="btn btn-sm btn-wide BuyOnCreditButton">
                      Buy on Credit
                    </button>
                    <img
                      className="rating2"
                      src="assets/img/credit_page/rating.png"
                      alt="Get it on Google Play"
                    />
                    <p className="ratingclass">450 reviews</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-md-3 col-lg-3 py-4 px-2 everycard"
              style={{ marginTop: "-50px" }}
            >
              <div className="card border-0 cardClass">
                <img
                  className="card-image"
                  src="assets/img/credit_page/product_images/switch_image.png"
                  alt="prices"
                />
                <div className="card-body card-content-body">
                  <div>
                    <h6 className="card-title">Socket & Switchgears</h6>
                    <p className="card-text">
                      Instant credit limit upto Rs. 10 lacs
                    </p>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <button className="btn btn-sm btn-wide BuyOnCreditButton">
                      Buy on Credit
                    </button>
                    <img
                      className="rating2"
                      src="assets/img/credit_page/rating.png"
                      alt="Get it on Google Play"
                    />
                    <p className="ratingclass">450 reviews</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-lg-3 py-4 px-2 everycard">
              <div className="card border-0 cardClass for_responsive">
                <img
                  className="card-image"
                  src="assets/img/credit_page/product_images/pvc_pipes2.png"
                  alt="prices"
                />
                <div className="card-body card-content-body">
                  <div>
                    <h6 className="card-title">PVC conduit pipes</h6>
                    <p className="card-text">
                      Instant credit limit upto Rs. 10 lacs
                    </p>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <button className="btn btn-sm btn-wide BuyOnCreditButton">
                      Buy on Credit
                    </button>
                    <img
                      className="rating2"
                      src="assets/img/credit_page/rating.png"
                      alt="Get it on Google Play"
                    />
                    <p className="ratingclass">450 reviews</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-3 col-lg-3 py-4 px-2 everycard"
              style={{ marginTop: "-50px" }}
            >
              <div className="card border-0 cardClass">
                <img
                  className="card-image"
                  src="assets/img/credit_page/product_images/switch_socket.png"
                  alt="prices"
                />
                <div className="card-body card-content-body">
                  <div>
                    <h6 className="card-title">Switches</h6>
                    <p className="card-text">
                      Instant credit limit upto Rs. 10 lacs
                    </p>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <button className="btn btn-sm btn-wide BuyOnCreditButton">
                      Buy on Credit
                    </button>
                    <img
                      className="rating2"
                      src="assets/img/credit_page/rating.png"
                      alt="Get it on Google Play"
                    />
                    <p className="ratingclass">450 reviews</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="row for_responsive2">
            <div className="col-md-3 col-lg-3 py-4 px-2 everycard">
              <div className="card border-0 cardClass">
                <img
                  className="card-image"
                  src="assets/img/credit_page/product_images/wires-home.png"
                  alt="prices"
                />
                <div className="card-body card-content-body">
                  <div>
                    <h6 className="card-title">Home Wires</h6>
                    <p className="card-text">
                      Instant credit limit upto Rs. 10 lacs
                    </p>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <button className="btn btn-sm btn-wide BuyOnCreditButton">
                      Buy on Credit
                    </button>
                    <img
                      className="rating2"
                      src="assets/img/credit_page/rating.png"
                      alt="Get it on Google Play"
                    />
                    <p className="ratingclass">450 reviews</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-md-3 col-lg-3 py-4 px-2 everycard"
              style={{ marginTop: "-50px" }}
            >
              <div className="card border-0 cardClass">
                <img
                  className="card-image"
                  src="assets/img/credit_page/product_images/lamps-home.png"
                  alt="prices"
                />
                <div className="card-body card-content-body">
                  <div>
                    <h6 className="card-title">Lamps & Bulbs</h6>
                    <p className="card-text">
                      Instant credit limit upto Rs. 10 lacs
                    </p>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <button className="btn btn-sm btn-wide BuyOnCreditButton">
                      Buy on Credit
                    </button>
                    <img
                      className="rating2"
                      src="assets/img/credit_page/rating.png"
                      alt="Get it on Google Play"
                    />
                    <p className="ratingclass">450 reviews</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-lg-3 py-4 px-2 everycard">
              <div className="card border-0 cardClass for_responsive">
                <img
                  className="card-image"
                  src="assets/img/credit_page/product_images/door-bell-home.png"
                  alt="prices"
                />
                <div className="card-body card-content-body">
                  <div>
                    <h6 className="card-title">Home Door Bells</h6>
                    <p className="card-text">
                      Instant credit limit upto Rs. 10 lacs
                    </p>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <button className="btn btn-sm btn-wide BuyOnCreditButton">
                      Buy on Credit
                    </button>
                    <img
                      className="rating2"
                      src="assets/img/credit_page/rating.png"
                      alt="Get it on Google Play"
                    />
                    <p className="ratingclass">450 reviews</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-3 col-lg-3 py-4 px-2 everycard"
              style={{ marginTop: "-50px" }}
            >
              <div className="card border-0 cardClass">
                <img
                  className="card-image"
                  src="assets/img/credit_page/product_images/MCB-home.png"
                  alt="prices"
                />
                <div className="card-body card-content-body">
                  <div>
                    <h6 className="card-title">MCB & RCCB</h6>
                    <p className="card-text">
                      Instant credit limit upto Rs. 10 lacs
                    </p>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <button className="btn btn-sm btn-wide BuyOnCreditButton">
                      Buy on Credit
                    </button>
                    <img
                      className="rating2"
                      src="assets/img/credit_page/rating.png"
                      alt="Get it on Google Play"
                    />
                    <p className="ratingclass">450 reviews</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="row for_responsive2">
            <div className="col-md-3 col-lg-3 py-4 px-2 everycard">
              <div className="card border-0 cardClass">
                <img
                  className="card-image"
                  src="assets/img/credit_page/product_images/bulb_image.png"
                  alt="prices"
                />
                <div className="card-body card-content-body">
                  <div>
                    <h6 className="card-title">LED Bulbs</h6>
                    <p className="card-text">
                      Instant credit limit upto Rs. 10 lacs
                    </p>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <button className="btn btn-sm btn-wide BuyOnCreditButton">
                      Buy on Credit
                    </button>
                    <img
                      className="rating2"
                      src="assets/img/credit_page/rating.png"
                      alt="Get it on Google Play"
                    />
                    <p className="ratingclass">450 reviews</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-md-3 col-lg-3 py-4 px-2 everycard"
              style={{ marginTop: "-50px" }}
            >
              <div className="card border-0 cardClass lastCardClass">
                <img
                  className="card-image"
                  src="assets/img/credit_page/product_images/casing-capping.png"
                  alt="prices"
                />
                <div className="card-body card-content-body">
                  <div>
                    <h6 className="card-title">Casing & Capping</h6>
                    <p className="card-text">
                      Instant credit limit upto Rs. 10 lacs
                    </p>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <button className="btn btn-sm btn-wide BuyOnCreditButton">
                      Buy on Credit
                    </button>
                    <img
                      className="rating2"
                      src="assets/img/credit_page/rating.png"
                      alt="Get it on Google Play"
                    />
                    <p className="ratingclass">450 reviews</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card border-0 for_responsive lastcard">
                <img
                  className="card-image"
                  src="assets/img/credit_page/product_images/boxes-and-enclosers.png"
                  alt="prices"
                />
                <div className="card-body card-content-body">
                  <div>
                    <h6 className="card-title">Boxes and Enclosers</h6>
                    <p className="card-text">
                      Instant credit limit upto Rs. 10 lacs
                    </p>
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <button className="btn btn-sm btn-wide BuyOnCreditButton">
                      Buy on Credit
                    </button>
                    <img
                      className="rating2last"
                      src="assets/img/credit_page/rating.png"
                      alt="Get it on Google Play"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="rope_image2"
          src="assets/img/credit_page/rope_image2.png"
          alt="sell_page"
        />
      </div>
    </>
  );
};

export default ProductSection;
