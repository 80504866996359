import React from "react";

const TermsAndConditions = () => {
  return (
    <>
      <div className="term-and-cond">
        <h4>
          Daqi Website and SSO-ID Terms & Conditions Use of the Daqi
          Platform/Daqi Services
        </h4>
        <hr />
        <p>
          Please read the following terms and conditions carefully before
          registering on, accessing, browsing, downloading or using the Daqi
          website located at Daqi.in, and all associated sites linked to
          Daqi.in, or the Daqi mobile application or any similar platform
          (hereinafter collectively, the Daqi Platform run by Ptolemy Technology
          Private Limited, having its registered office at HNO-218, B, Tudil
          mahad, Tudil, Raigad Ambivali Mahad Raigarh(MH) MH 402301 (hereinafter
          collectively, Daqi or Daqi) on any device and/or before availing any
          services offered by Daqi on the Daqi Platform which may include
          services such as recharge or bill payment, digital products,
          semi-closed wallet service and marketplace service or any other
          service that may be offered by Daqi on the Daqi Platform (hereinafter
          individually, and collectively, the Daqi Services). For the avoidance
          of doubt, it is clarified that these terms and conditions shall apply
          to all Daqi Services, whether offered by Daqi or its affiliates.
        </p>
        <h4>Acceptance</h4>
        <p>
          By registering on, accessing, browsing, downloading or using the Daqi
          Platform for any general purpose or for the specific purpose of
          availing any Daqi Service, You agree to be bound by the single-sign-on
          ID (hereinafter SSOID) terms and conditions set forth below as well as
          by the service-specific terms and conditions applicable to each Daqi
          Service (hereinafter collectively, the T&Cs). These T&Cs shall also
          include any additional or modified terms and conditions in relation to
          the SSOID or any additional or modified service-specific terms and
          conditions in relation to any Daqi Service or any future service that
          may be offered by Daqi on the Daqi Platform. By registering on,
          accessing, browsing, downloading or using (as applicable) the Daqi
          Platform or availing any Daqi Service or the SSOID, You automatically
          and immediately agree to all the T&Cs. If at any time You do not
          accept or agree with any of the T&Cs or do not wish to be bound by the
          T&Cs, You may not access, browse or use the Daqi Platform and
          immediately terminate Your availing the Daqi Services. Accepting or
          agreeing to the T&Cs will constitute a legal contract (hereinafter
          Agreement) between You, being at least 18 years of age and an
          individual user of the Daqi Platform or a customer or beneficiary of
          the Daqi Services, and Daqi. All services are rendered by Daqi through
          the Daqi Platform under the brand name “Daqi” (or any derivatives or
          variations thereof). Consequently, all the rights, benefits,
          liabilities and obligations under the T&Cs shall, as the case may be,
          accrue to the benefit of, or incurred by, Daqi, regarding Your use of
          Daqi’s digital services (which includes but may not be limited to
          prepaid recharge, bill payment, booking movie tickets, bus tickets,
          hotel rooms or flight tickets), the semi closed wallet service, the
          marketplace service or any such other services which may be added on
          the Daqi Platform and which will henceforth be a Daqi Service, from
          time to time. The Daqi Services shall be used by You subject to Your
          adherence with the T&Cs. As long as You accept and comply with these
          T&Cs, Daqi grants You a personal, non-exclusive, non-transferable,
          limited, revocable privilege to enter and use the Daqi Platform and/or
          avail the Daqi Services.
        </p>
        <h4>Eligibility</h4>
        <p>
          The Daqi Services are not available to persons under the age of 18 or
          to anyone previously suspended or removed by Daqi from availing the
          Daqi Services or accessing the Daqi Platform. By accepting the T&Cs or
          by otherwise using the Daqi Services on the Daqi Platform, You
          represent that You are at least 18 years of age and have not been
          previously suspended or removed by Daqi, or disqualified for any other
          reason, from availing the Daqi Services or using the Daqi Platform. In
          addition, You represent and warrant that You have the right, authority
          and capacity to enter into this Agreement and to abide by all the T&Cs
          as part of this Agreement. Finally, You shall not impersonate any
          person or entity, or falsely state or otherwise misrepresent Your
          identity, age or affiliation with any person or entity. Finally, in
          the event of any violation of the T&Cs, Daqi reserves the right to
          suspend or permanently prevent You from availing Daqi Services or
          using the Daqi Platform.
        </p>
        <h4>Other Terms and Conditions</h4>
        <p>
          Additional terms and conditions may apply in order for You to avail
          specific Daqi Services and to specific portions or features of the
          Daqi Platform, including contests, promotions or other similar
          features, all of which terms are made a part of these T&Cs by this
          reference. You agree to abide by such other terms and conditions,
          including where applicable representing that You are of sufficient
          legal age to use or participate in such service or feature. If there
          is a conflict between these T&Cs and the terms posted for or
          applicable to a specific portion of the Daqi Platform or for any Daqi
          Service offered on or through the Daqi Platform, the latter terms
          shall control with respect to Your use of that portion of the Daqi
          Platform or the specific Daqi Service. Daqi may make changes to any
          Daqi Services offered on the Daqi Platform, or to the applicable terms
          for any such Daqi Services, at any time, without notice. The materials
          on the Daqi Platform with respect to the Daqi Services may be out of
          date, and Daqi makes no commitment to update the materials on the Daqi
          Platform with respect to such Daqi Services. The following terms also
          govern and apply to Your use of the Daqi Platform, and they are
          incorporated herein by this reference:
          https://Daqi.in/company/terms-and-conditions?company=Daqi&tab=terms
        </p>
        <h4>SSOID Service, Participating Platforms</h4>
        <p>
          Daqi’s SSOID service offers You the convenience, after a one-time
          registration any Daqi Platform, to sign on (log in) to all web pages
          and online platforms operated by Daqi or its affiliates (hereinafter
          the Participating Platforms) with single log-in access / data, without
          going through a separate registration process every time. After
          successfully registering for the SSOID Service, the user can log-in
          via each Participating Platform with his SSOID access credentials.
          Moreover, if already logged in to one of the Participating Platforms,
          the user can immediately use all other Participating Platforms without
          additional log-in procedures (subject to the respective Participating
          Platform requiring further declarations). The Participating Platforms
          that offer the SSOID as a login feature, are visible on registration
          and in the user account. The number of Participating Platforms may
          also vary in the course of time. With regard to the contractual
          relationships of the users with the respective Participating
          Platforms, for the purposes of which Daqi’s SSOID service is or can be
          used in accordance with these T&Cs, these can alternatively have their
          own commercial and user terms and conditions. These business or user
          terms and conditions are available on the respective Participating
          Platforms. For the respective contents of the Participating Platforms,
          the respective affiliate of Daqi is solely responsible only for those
          content that it actually operates. For the SSOID service, these T&Cs
          apply exclusively, whereas for the use of the Participating Platforms,
          their business and user terms and conditions apply along with these
          T&Cs. The SSOID shall be owned and operated by Daqi for use on all
          Participating Platforms. For the use of Daqi’s SSOID service, the user
          must register. Registration as a user is only allowed if the user
          meets the eligibility criteria as set out in these T&Cs. Each user can
          set up only one user account. The user is obliged to provide correct
          and complete information during the registration and use of the SSOID
          service. The login data is intended solely for personal use by the
          user and therefore always to be kept secret and safe. The user is not
          entitled to share his login details with third parties to use the
          SSOID service or to disclose them otherwise. The user is obliged to
          inform Daqi immediately on becoming aware of and/or suspecting a case
          of any unauthorized use, disclosure and/or misuse of their access data
          or of their user account. Furthermore, the user is obliged not to use
          the user account of another person. Submission of the duly completed
          registration form by the user simply provides invitation to us to make
          an offer to enter into the agreement on the use of our SSOID service
          (hereinafter the SSOID Agreement). Our offer for the conclusion of the
          SSOID Agreement is then effected by the fact that Daqi sends the user
          a confirmation by e mail, in which a confirmation link is included
          next to the user name and the password for the SSOID Service. By the
          user clicking on this confirmation link, the user accepts Daqi’s
          offer, so that in this way the SSOID Agreement enters into force.
          Conclusion of the SSOID Agreement may, also be effected through other
          communication modes and following such processes which help achieve
          the same purpose as the aforementioned e-mail confirmation process.
          Daqi is entitled to reject individual users without giving reasons.
          The user guarantees that the data submitted for their user account
          (and any other information that is otherwise left for Daqi) are in all
          respects complete, true, accurate and not misleading. Any changes to
          user’s data should be promptly updated correctly in its account. If
          the mobile phone number or e-mail address provided by You to Daqi
          Platform for the SSOID service is transferred/surrendered/deactivated
          due to any reason, it shall be your responsibility to contact Daqi and
          have the number/email address duly updated in Daqi records before it
          is transferred/surrendered/deactivated. In case of any failure to have
          the updation request duly raised with Daqi, Daqi shall not be liable
          for any misuse of Your account.
        </p>
        <h4>Communication Policy</h4>
        <p>By accepting the T&Cs, You accept the following:</p>
        <ol>
          <li>
            Daqi may send alerts to the mobile phone number provided by You
            while registering with the Daqi Platform for the SSOID service or on
            any updated mobile number subsequently provided by You on the Daqi
            Platform, or via e-mail or push notifications. The alerts will be
            received in case of SMS, only if the mobile phone is in ‘On’ mode to
            receive the SMS, in case of e-mail, only if the e-mail servers and
            e-mail ids are functional, and in case of push notifications, if the
            user has enabled the receipt of such notifications. If the mobile
            phone is in ‘Off’ mode or if the e-mail servers or ids are not
            functional or if the push-notifications feature has been turned off,
            then You may not get the alert at all or get delayed messages.
          </li>
          <li>
            Daqi will make best efforts to provide alerts via SMS/e-mail/push
            notifications and it shall be deemed that You shall have received
            the information sent from Daqi as an alert on the mobile phone
            number or e-mail id provided during the course of, or in relation
            to, using the Daqi Platform or availing any Daqi Services. Daqi
            shall not be under any obligation to confirm the authenticity of the
            person(s) receiving the alert. You cannot hold Daqi liable for
            non-availability of the SMS/email alert/push notifications service
            in any manner whatsoever.
          </li>
          <li>
            You authorize Daqi to contact You and communicate with You for any
            Daqi Service or Offer(s). Daqi may use third party service providers
            to send alerts or communicate with You. You authorize Daqi and Daqi
            Entities to override the DND settings to reach out to You over
            calls, SMS, emails and any other mode of communication.
          </li>
          <li>
            The SMS/e-mail alert/push notification service provided by Daqi is
            an additional facility provided for Your convenience and that it may
            be susceptible to error, omission and/or inaccuracy. In the event
            that You observe any error in the information provided in the alert,
            Daqi shall be immediately informed about the same by You and Daqi
            will make best possible efforts to rectify the error as early as
            possible. You shall not hold Daqi liable for any loss, damages,
            claim, expense including legal cost that may be incurred/suffered by
            You on account of the SMS/e-mail alert/push notification facility.
          </li>
          <li>
            The clarity, readability, accuracy and promptness of providing the
            SMS/e-mail alert/push notification service depend on many factors
            including the infrastructure and connectivity of the service
            provider. Daqi shall not be responsible for any non-delivery,
            delayed delivery or distortion of the alert in any way whatsoever.
          </li>
          <li>
            You will indemnify and hold harmless Daqi and the SMS/e-mail service
            provider including its officials from any damages, claims, demands,
            proceedings, losses, costs, charges and expenses whatsoever
            including legal charges and attorney’s fees which Daqi or the
            SMS/e-mail service provider may at any time incur, sustain, suffer
            or be subjected to as a consequence of, or arising out of, any of
            the following: (i) misuse by You or improper or fraudulent
            information provided by You; (ii) incorrect number or a number that
            belongs to an unrelated third party provided by You; and/or (iii)
            the customer receiving any message relating to the reservation
            number, travel itinerary information, booking confirmation,
            modification to a ticket, cancellation of ticket, change in bus
            schedule, delay, and/or rescheduling from Daqi and/or the SMS/e-mail
            service provider.
          </li>
        </ol>
        <h4>Use of Daqi Platform</h4>
        <p>
          You understand that except for information, products or services
          clearly indicated as being supplied by Daqi, we do not operate,
          control, or endorse any information, products or services on the
          Internet in anyway. You also understand that Daqi cannot and does not
          guarantee or warrant that files available for download through the
          Daqi Platform will be free of viruses, worms or other code that may be
          damaging. You are responsible for implementing procedures to satisfy
          Your particular requirements of Internet security and for accuracy of
          data input and output.
        </p>
        <h4>Prohibited Conduct</h4>
        <p>
          By accessing or using the Daqi Platform or by availing Daqi Services,
          You agree not to:
        </p>
        <ol>
          <li>Violate the T&Cs;</li>
          <li>
            impersonate any person or entity, falsely claim or otherwise
            misrepresent Your affiliation with any person or entity, or access
            the accounts of others without permission, forge another person’s
            digital signature, misrepresent the source, identity, or content of
            information transmitted via the Daqi Services, perform any other
            similar fraudulent activity or otherwise avail Daqi Services with
            what we reasonably believe to be potentially fraudulent funds.
          </li>
          <li>
            infringe our or any third party’s intellectual property rights,
            rights of publicity or privacy.
          </li>
          <li>
            use the Daqi Services if You are under the age of 18 years without a
            parental sponsor or, in any event, use the Daqi Services if You are
            under the age of 13 years old even with a parental sponsor and in
            accordance with applicable law.
          </li>
          <li>
            post or transmit any message which is libellous, defamatory or which
            discloses private or personal matters concerning any person.
          </li>
          <li>
            post or transmit any message, data, image or program which is
            pornographic, vulgar or offensive in nature.
          </li>
          <li>
            refuse to cooperate in an investigation or provide confirmation of
            Your identity or any other information provide by You to Daqi.
          </li>
          <li>
            remove, circumvent, disable, damage or otherwise interfere with
            security-related features of the Daqi Services and the Daqi Platform
            or features that enforce limitations on the use of the Daqi Services
            or the Daqi Platform.
          </li>
          <li>
            reverse engineer, decompile, disassemble or otherwise attempt to
            discover the source code of as regards Daqi Services or Daqi
            Platform or any part thereof, except and only to the extent this is
            expressly permissible by applicable law.
          </li>
          <li>
            use the Daqi Services or the Daqi Platform in any manner that could
            damage, disable, overburden, or impair it, including, without
            limitation, using the Daqi Services or the Daqi Platform in an
            automated manner.
          </li>
          <li>
            modify, adapt, translate or create derivative works based upon the
            Daqi Services and the Daqi Platform or any part thereof, except and
            only to the extent that that this is permissible by applicable law;
          </li>
          <li>
            intentionally interfere with or damage operation of the Daqi
            Services or the Daqi Platform or any other user’s enjoyment of it,
            by any means, including uploading or otherwise disseminating
            viruses, adware, spyware, worms, or other malicious code or file
            with contaminating or destructive features.
          </li>
          <li>
            use any robot, spider, other automatic device, or manual process to
            monitor or copy the Daqi Platform without prior written permission.
          </li>
          <li>
            interfere or disrupt the Daqi Platform or networks connected to the
            Daqi Platform.
          </li>
          <li>
            take any action that imposes an unreasonably or disproportionately
            large load on Daqi’s infrastructure/network.
          </li>
          <li>
            use any device, software or routine to bypass the Daqi Platform’s
            robot exclusion headers, or interfere or attempt to interfere, with
            the Daqi Services.
          </li>
          <li>
            forge headers or manipulate identifiers or other data in order to
            disguise the origin of any content transmitted through the Daqi
            Platform or to manipulate Your presence on the Daqi Platform.
          </li>
          <li>
            sell the Daqi Services, information, or software associated with or
            derived from it.
          </li>
          <li>
            use the facilities and capabilities of the Daqi Platform to conduct
            any activity or solicit the performance of any illegal activity or
            other activity which infringes the rights of others.
          </li>
          <li>
            breach this Agreement, the SSOID Agreement or any other agreement or
            policy as may be applicable pursuant to the T&Cs.
          </li>
          <li>provide false, inaccurate or misleading information.</li>
          <li>
            use the Daqi Platform to collect or obtain personal information,
            including without limitation, financial information, about other
            users of the Daqi Platform, except and only as expressly provided in
            the T&Cs.
          </li>
          <li>
            avail Daqi Services with what Daqi reasonably believes to be
            potentially fraudulent funds.
          </li>
          <li>
            use the Daqi Services in a manner that results in or may result in
            complaints, disputes, reversals, chargebacks, fees, fines, penalties
            and other liability to Daqi, a third party or You.
          </li>
          <li>
            use the Daqi Services in a manner that Daqi or any payment card
            network reasonably believe to be an abuse of the payment card system
            or a violation of payment card network rules.
          </li>
          <li>
            take any action that may cause Daqi to lose any of the Daqi Services
            from its service providers or lose any of its recharge partners or
            business partners, including mobile operators or telecom companies,
            payment processors or other suppliers.
          </li>
          <li>
            send automated request of any kind to the Daqi Platform without
            express permission in advance from Daqi.
          </li>
        </ol>
        <h4>Termination; Agreement Violations</h4>
        <p>
          You agree that Daqi, in its sole discretion, for any or no reason, and
          without penalty, may suspend or terminate Your account (or any part
          thereof) or Your use of the Daqi Services/Daqi Platform and remove and
          discard on the Daqi Platform all or any part of Your account, Your
          user profile, or Your recipient profile, including Your SSOID, at any
          time. Daqi may also in its sole discretion and at any time discontinue
          providing access to the Daqi Services, or any part thereof, with or
          without notice. You agree that any termination of Your access to the
          Daqi Services/Daqi Platform or any account You may have or portion
          thereof may be effected without prior notice, and You agree that Daqi
          will not be liable to You or any third party for any such termination
          or interruption. Any suspected fraudulent, abusive or illegal activity
          may be referred to appropriate law enforcement authorities. These
          remedies are in addition to any other remedies Daqi may have at law or
          in equity. Upon termination for any reason, You agree to immediately
          stop using the Daqi Services/Daqi Platform.
        </p>
        <h4>Limitation of Liability and Damages</h4>
        <p>
          In no event, Daqi or its contractors, agents, licensors, partners or
          suppliers will be liable to You for any special, indirect, incidental,
          consequential, punitive, reliance, or exemplary damages (including
          without limitation lost business opportunities, lost revenues, or loss
          of anticipated profits or any other pecuniary or non-pecuniary loss or
          damage of any nature whatsoever) arising out of or relating to: (i)
          this Agreement; (ii) the SSOID Agreement; (iii) the Daqi Services, the
          Daqi Platform or any reference site/app/platform/service; or (iv) Your
          use or inability to use the Daqi Services, the Daqi Platform
          (including any and all materials) or any reference
          sites/app/platform/service, even if Daqi or a Daqi authorized
          representative has been advised of the possibility of such damages. In
          no event, Daqi, directors, employees, agents will be liable to You for
          any damages, liabilities, losses, and causes of action arising out of
          or relating to: (i) this Agreement; (ii) the SSOID Agreement; (iii)
          the Daqi Services, the Daqi Platform or any reference
          site/app/platform/service; or (iv) Your use or inability to use the
          Daqi services, the Daqi Platform (including any and all materials) or
          any reference sites/app/platform/service; or (v) any other
          interactions with Daqi, however caused and whether arising in
          contract, tort including negligence, warranty or otherwise, beyond or
          in excess of the amount paid by You, if any, for using the portion of
          the Daqi Service or the Daqi Platform giving rise to the cause of
          action, or beyond or in excess Rs. 5,000, whichever is less. You
          acknowledge and agree that Daqi has offered its products and services,
          set its prices, and entered into this Agreement in reliance upon the
          warranty disclaimers and the limitations of liability set forth
          herein, that the warranty disclaimers and the limitations of liability
          set forth herein reflect a reasonable and fair allocation of risk
          between You and Daqi, and that the warranty disclaimers and the
          limitations of liability set forth herein form an essential basis of
          the bargain between You and Daqi. Daqi would not be able to provide
          the services to You on an economically reasonable basis without these
          limitations. Applicable law may not completely allow the limitation or
          exclusion of liability or incidental or consequential damages, so the
          above limitations or exclusions will apply to You subject to
          applicable law. In such cases, Daqi’s liability will be limited to the
          fullest extent permitted by applicable law. This paragraph shall
          survive the termination of this Agreement.
        </p>
        <h4>Indemnification</h4>
        <p>
          You agree to indemnify, save, and hold Daqi, its affiliates,
          employees, officers, directors and partners harmless from any and all
          claims, losses, damages, and liabilities, costs and expenses,
          including without limitation legal fees and expenses, arising out of
          or related to: (i) Your use or misuse of the Daqi Services or of the
          Daqi Platform; (ii) any violation by You of this Agreement or the
          SSOID Agreement; or (iii) any breach of the representations,
          warranties, and covenants made by You herein. Daqi reserves the right,
          at Your expense, to assume the exclusive defense and control of any
          matter for which You are required to indemnify Daqi, including rights
          to settle, and You agree to cooperate with Daqi’s defense and
          settlement of these claims. Daqi will use reasonable efforts to notify
          You of any claim, action, or proceeding brought by a third party that
          is subject to the foregoing indemnification upon becoming aware of it.
          This paragraph shall survive termination of this Agreement
        </p>
        <h4>Disclaimer; No Warranties</h4>
        <p>
          To the fullest extent permissible pursuant to applicable law, Daqi and
          its third-party partners disclaim all warranties or guarantees –
          whether statutory, express or implied – including, but not limited to,
          implied warranties of merchantability, fitness for a particular
          purpose, and non-infringement of proprietary rights. No advice or
          information, whether oral or written, obtained by You from Daqi or
          through the Daqi Services or the Daqi Platform will create any
          warranty or guarantee other than those expressly stated herein. For
          the purposes of this Disclaimer, You expressly acknowledge that as
          used in this section, the term “Daqi” includes Daqi’s officers,
          directors, employees. You acknowledge that Daqi is a reseller of
          mobile recharges and is not liable for any third party (telecom
          companies, mobile operators or suppliers) obligations due to rates,
          quality and all other instances, whether to any such telecom
          companies’ subscribers or otherwise. You expressly agree that use of
          the Daqi Services on the Daqi Platform is at Your sole risk. It is
          Your responsibility to evaluate the accuracy, completeness and
          usefulness of all opinions, advice, services, merchandise and other
          information provided through the site or on the Internet generally.
          Daqi does not warrant that the Daqi Services will be uninterrupted or
          error-free or that defects in the site will be corrected. The Daqi
          Services and the Daqi Platform and any data, information, third party
          software, reference sites, services, or software made available in
          conjunction with or through the services and the site are provided on
          an “as is” and “as available,” “with all faults” basis and without
          warranties or representations of any kind either express or implied.
          Daqi, and its partners do not warrant that the data, Daqi software,
          functions, or any other information offered on or through the Daqi
          Services/Daqi Platform or any reference sites/platforms/apps/services
          will be uninterrupted, or free of errors, viruses or other harmful
          components and do not warrant that any of the foregoing will be
          corrected. Daqi and its licensors, and partners do not warrant or make
          any representations regarding the use or the results of the use of the
          Daqi Services/Daqi Platform or any reference
          sites/platforms/apps/services in terms of correctness, accuracy,
          reliability, or otherwise. You understand and agree that You use,
          access, download, or otherwise obtain information, materials, or data
          through the Daqi Services/Daqi Platform or any reference
          sites/platforms/apps/services at Your own discretion and risk and that
          You will be solely responsible for any damage to Your property
          (including Your computer system and mobile device or any other
          equipment) or loss of data that results from the download or use of
          such material or data. We do not authorize anyone to make any warranty
          on our behalf and You should not rely on any such statement. This
          paragraph shall survive termination of this Agreement. In no event
          will Daqi be liable for any incidental, consequential, or indirect
          damages (including, but not limited to, damages for loss of profits,
          business interruption, loss of programs or information, and the like)
          arising out of the use of or inability to use the Daqi Platform.
        </p>
        <h4>Ownership; Proprietary Rights</h4>
        <p>
          The Daqi Services and the Daqi Platform are owned and operated by
          Daqi. The visual interfaces, graphics, design, compilation,
          information, computer code (including source code and object code),
          products, software, services, and all other elements of the Daqi
          Services and the Daqi Platform provided by Daqi (hereafter Materials)
          are protected by Indian copyright, trade dress, patent, and trademark
          laws, international conventions, and all other relevant intellectual
          property and proprietary rights, and applicable laws. As between You
          and Daqi, all Materials, trademarks, service marks, and trade names
          contained on the Daqi Platform are the property of Daqi. You agree not
          to remove, obscure, or alter Daqi or any third party’s copyright,
          patent, trademark, or other proprietary rights notices affixed to or
          contained within or accessed in conjunction with or through the Daqi
          Services/Daqi Platform. Except as expressly authorized by Daqi, You
          agree not to sell, license, distribute, copy, modify, publicly perform
          or display, transmit, publish, edit, adapt, create derivative works
          from, or otherwise make unauthorized use of the Materials. Daqi
          reserves all rights not expressly granted in this Agreement. If You
          have comments regarding the Daqi Services and/or the Daqi Platform or
          ideas on how to improve it, please contact customer service. Please
          note that by doing so, You hereby irrevocably assign to Daqi, and
          shall assign to Daqi, all rights, title and interests in and to all
          ideas and suggestions and any and all worldwide intellectual property
          rights associated therewith. You agree to perform such acts and
          execute such documents as may be reasonably necessary to perfect the
          foregoing rights.
        </p>
        <h4>Modification of this Agreement</h4>
        <p>
          Daqi reserves the right to change, modify, add, or remove portions of
          this Agreement (each, a change and collectively, changes) at any time
          by posting notification on the Daqi Platform or otherwise
          communicating the notification to You. The changes will become
          effective, and shall be deemed accepted by You, 24 hours after the
          initial posting and shall apply immediately on a going-forward basis
          with respect to Your use of the Daqi Platform, availing the Daqi
          Services or for payment transactions initiated after the posting date.
          If You do not agree with any such change, Your sole and exclusive
          remedy is to terminate Your use of the Daqi Services/Daqi Platform.
          For certain changes, Daqi may be required under applicable law to give
          You advance notice, and Daqi will comply with such requirements. Your
          continued use of the Daqi Platform following the posting of changes
          will mean that You accept and agree to the changes.
        </p>
        <h4>Notice</h4>
        <p>
          Daqi may provide You with notices and communications by e-mail, SMS,
          push notifications, regular mail or postings on the Daqi Platform or
          by any other reasonable means. Except as otherwise set forth herein,
          notice to Daqi must be sent by courier or registered mail to Daqi
          Communications Ltd.,
          <b>
            HNO-218, B, Tudil mahad, Tudil, Raigad Ambivali Mahad Raigarh(MH) MH
            402301, India.
          </b>
        </p>
        <h4>Waiver</h4>
        <p>
          The failure of Daqi to exercise or enforce any right or provision of
          this Agreement will not constitute a waiver of such right or
          provision. Any waiver of any provision of this Agreement will be
          effective only if in writing and signed by Daqi.
        </p>
        <h4>Dispute Resolution</h4>
        <p>
          If any dispute, controversy or claim arises under this Agreement or in
          relation to any Daqi Service or the Daqi Platform, including any
          question regarding the existence, validity or termination of this
          Agreement or T&Cs (hereinafter Dispute), the parties shall use all
          reasonable endeavours to resolve such Dispute amicably. If the parties
          are unable to resolve the Dispute amicably within 30 days of the
          notice of such Dispute, Daqi may elect to resolve any Dispute by a
          binding arbitration in accordance with the provisions of the Indian
          Arbitration & Conciliation Act, 1996 (hereinafter Act). Such Dispute
          shall be arbitrated on an individual basis and shall not be
          consolidated in any arbitration with any claim or controversy of any
          other party. The Dispute shall be resolved by a sole arbitrator,
          appointed in accordance with the Act. The seat of the arbitration
          shall be New Delhi and the language of this arbitration shall be
          English. Either You or Daqi may seek any interim or preliminary relief
          from a court of competent jurisdiction in Mumbai necessary to protect
          the rights or the property belonging to You or Daqi (or any of our
          agents, suppliers, and subcontractors), pending the completion of
          arbitration. Any arbitration shall be confidential, and neither You
          nor Daqi may disclose the existence, content or results of any
          arbitration, except as may be required by law or for purposes of
          enforcing the arbitration award. All administrative fees and expenses
          of arbitration will be divided equally between You and Daqi. In all
          arbitrations, each party will bear the expense of its own lawyers and
          preparation. This paragraph shall survive termination of this
          Agreement.
        </p>
        <h4>Governing Law and Forum for Disputes</h4>
        <p>
          Subject to the Dispute Resolution section above, You agree that any
          claim or dispute You may have against Daqi must be resolved by a court
          having jurisdiction in Mumbai, India. You agree to submit to the
          personal jurisdiction of the courts located within New Delhi, India,
          for the purpose of litigating all such claims or disputes. This
          Agreement shall be governed by Indian law. This paragraph shall
          survive termination of this Agreement.
        </p>
        <h4>Severability</h4>
        <p>
          If any provision of this Agreement is held to be unlawful, void,
          invalid or otherwise unenforceable, then that provision will be
          limited or eliminated from this Agreement to the minimum extent
          required, and the remaining provisions will remain valid and
          enforceable.
        </p>
        <h4>Survival</h4>
        <p>
          Upon termination of this Agreement, any provision which, by its nature
          or express terms should survive, will survive such termination or
          expiration as applied to transfers and relationship prior to such
          termination or expiration.
        </p>
        <h4>Headings</h4>
        <p>
          The heading references herein are for convenience purposes only and
          they do not constitute a part of these T&Cs and will not be deemed to
          limit or affect any of the provisions hereof.
        </p>
        <h4>Entire Agreement</h4>
        <p>
          This Agreement is the entire agreement between You and Daqi relating
          to the subject matter hereof and this Agreement will not be modified
          except by a change to this Agreement made by Daqi in accordance with
          the terms of this Agreement.
        </p>
        <h4>Augmont Terms & Conditions</h4>
        <p>
          These “Terms” and “Conditions of Use” (“Terms/Augmont Terms and
          Conditions”) constitute an electronic record within the meaning of the
          applicable laws. This electronic record is generated by a computer
          system and does not require any physical or digital signatures. These
          Terms shall be in addition to and not in derogation to any other terms
          as stipulated from time to time. By using this facility on the Daqi
          Platform, You are agreeing to and accept these Terms.
        </p>
        <p>
          <b>A. Gold Accumulation Plan Terms and Conditions</b>
        </p>
        <p>Please read these Terms carefully.</p>
        <ol>
          <li>
            In these Terms, references to “You/you/Your”, or “Customer” shall
            mean the counterparty who transacts using the Daqi
            Platform/Platform, being a natural person, buying Gold, redeeming
            Gold in the form of redeemable products, selling back Gold to
            Augmont. “Platform” shall mean, and include, the platforms (website,
            mobile apps, others) of Daqi that the Customer accesses for the
            transactions, including all contents, services and technology
            offered through the Platform. “Service Providers” mean independent
            third party service providers, and references to the “Seller”,
            “Augmont”, “we/We”, “us” and “our” shall mean Augmont Gold Private
            Limited. “Customer’s Gold Accumulation Plan Account”/ (“GAP
            account”)” shall mean gold accumulation plan account of the
            individual Customer identified through his/her Daqi account based in
            the registered mobile number present in records of Daqi. “Transfer”
            refers to a facility to transfer Gold from a GAP account to another
            Customer’s GAP account.
          </li>
          <li>
            Augmont or Daqi may revise these Terms from time to time and at any
            time. Such changes shall be effective when posted on the Platform
            and shall be deemed to be notified to the Customer. If you do not
            agree to be bound by these Terms and Conditions of Access and Use,
            as modified from time to time, you should leave the Platform
            immediately.
          </li>
          <li>
            Augmont/Daqi shall be entitled to collect and store relevant
            information and documents provided by the Customer. Further, as and
            when required by Augmont / Daqi based on the government regulations,
            the Customer will be required to provide additional documents for
            fulfilling the KYC requirements. You authorize Augmont and Daqi to
            make such enquiries as they may feel necessary to satisfy themselves
            about the validity of Your identity. You undertake to update us and
            Daqi in case of change of Your existing information or verification
            documents provided by You.
          </li>
          <li>
            You agree to provide true, accurate, current and complete
            information about yourself as prompted by the Platform and maintain
            and promptly update to keep it true, accurate, current and complete.
            If You have reasons to believe that there is an error in the
            information furnished to Augmont /Daqi You shall immediately advice
            the Augmont / One 97 in writing and forthwith provide correct /
            updated information.
          </li>
          <li>
            If you provide any information that is untrue, inaccurate, not
            current or incomplete, or Augmont/Daqi has reasonable grounds to
            suspect that such information is untrue, inaccurate, not current or
            incomplete, Augmont/Daqi has the right to suspend or terminate your
            account/GAP account and refuse any and all current or future use of
            the Platform (or any portion thereof) without any liability to you.
            You hereby undertake to indemnify and keep indemnified Daqi against
            any and all losses, claims, liabilities costs etc. which arise out
            of or relating to Your failure to identify Yourself and validate
            Your account/GAP account promptly and / or due to incorrect KYC
            documents / information.
          </li>
          <li>
            You can offer to buy Gold worth Rs. 1.00 (Rupee One Only) and above
            incremental value thereof at the live purchase price of Gold of
            99.9% purity displayed on the Platform.
          </li>
          <li>
            Payment will be accepted only through Your Daqi wallet or other
            payment options made available on the Platform. At the time of
            purchase / redemption / sale-back / transfer of Gold, the relevant
            taxes will be chargeable as applicable as per the Government
            regulations.
          </li>
          <li>
            At the time of redemption, the Customer can select redeemable
            product for redemption from catalogue of redeemable products. These
            redeemable products will be hosted on the Platform. The list of
            redeemable products may be altered, changed and amended by Augmont
            from time to time at its sole discretion.
          </li>
          <li>
            At the time of redemption in physical deliverable product form, the
            Customer will pay additional making and delivery charges and
            applicable taxes on the same. The redeemable product selected from
            the catalogue for delivery shall be subject to availability of stock
            with Augmont.
          </li>
          <li>
            For each confirmed purchase of Gold by the Customer, Augmont will
            transfer the metal to the vaulting agencies and it will be held
            there, under the supervision of an independent trustee, till (a))
            sell-back by the Customer, (b) redemption by the Customer.
          </li>
          <li>
            The Customer will have the option of redeeming and asking for
            physical delivery of the full or part of the accumulated gold
            holding, in the form of redeemable products made available on the
            Platform from time to time. The residual fractional gold remaining,
            upon redemption / Transfer by You of the entire gold holding, can be
            sold back by You to us at the live sell-back price of gold of 99.9%
            purity and the corresponding amount against the residual fractional
            gold sold by You will be credited in Your Daqi wallet instantly.
          </li>
          <li>
            You need to pay the applicable making and delivery charges from Your
            Daqi wallet or other payment instruments made available on the
            Platform in order to redeem the redeemable product of Your choice.
          </li>
          <li>
            Once the redemption has been initiated by You and payment received
            then Your gold grams will be debited accordingly from Your GAP
            account.
          </li>
          <li>
            Augmont may also, from time to time in future, offer/permit the
            Customer to sell-back its gold in fraction to Augmont at prevailing
            live sell-back price of gold of 99.9% purity, during the offer
            period as may be announced / permitted by Augmont. The money against
            the fractional gold sold by a Customer would be credited to the
            Customer.
          </li>
          <li>
            Live purchase price of Gold will be displayed on the Platform and is
            subject to change from time to time. At the time You make request
            for redemption / sell-back, the value of Gold / quantity of Gold to
            be redeemed / sold back will be displayed based on live sell-back
            price of Gold. The live purchase price of Gold and live sell-back
            price of Gold may vary and also may be different from that available
            in the open market and/or in any other retail outlet. Your offer may
            be rejected irrespective of whether an item has been earmarked/wish
            listed by a Customer. In such an event if You still wish to offer to
            purchase Gold or sell-back residual fractional Gold, You may make
            another offer at the changed price. Augmont/Daqi disclaims any and
            all claims and/or liabilities arising from such non acceptance of
            offer or revision in prices.
          </li>
          <li>
            While availing any of the payment method/s available on the
            Platform, Augmont/Daqi will not be responsible or assume any
            liability, whatsoever in respect of any loss or damage arising
            directly or indirectly to the Customer due to:
            <ol>
              <li>Lack of authorization for any transactions,</li>
              <li>
                Exceeding the preset limit mutually agreed by and between the
                Customer and relevant banks of the Customer,
              </li>
              <li>Any payment issues arising out of the transaction,</li>
              <li>
                Illegitimacy of the payment methods (credit/debit card frauds
                etc.) being used by a Customer,
              </li>
              <li>
                Temporarily discontinuation of invitation to offer and / or
              </li>
              <li>Decline of transaction for any reason(s) whatsoever.</li>
            </ol>
          </li>
          <li>
            Augmont may, at its discretion, prescribe maximum
            individual/cumulative limits for Customers to accumulate gold or
            transfer Gold through this scheme from time to time, and reserves
            the right to refuse to accept order exceeding such limit.
          </li>
          <li>
            At any point of time on any day Augmont may temporarily discontinue
            the invitation to offer for any reason whatsoever.
          </li>
          <li>
            Augmont will, at its discretion, close down GAP account of
            Customers, if (a) they are inactive accounts,(for six months) (b) if
            Augmont decides to discontinue the scheme. In such an event,
            Customers will be required to redeem and / or sell-back their gold
            holdings. In the event the Customer chooses not to respond, then
            Augmont reserves the right to repurchase the gold holding of the
            Customer, at the then prevalent live sell-back price.
          </li>
          <li>
            Any Gold lying in vault, and business deposit is non transferrable
            unless specifically allowed by Augmont. However in case of death or
            insanity, Augmont shall transfer such Gold, and advance balance to
            Your legal heir(s) after the required due diligence and Your legal
            heir(s) shall be regarded as the registered user thereafter.
          </li>
          <li>
            The Customer will not be entitled to redeem the Gold if the zip code
            of delivery address is outside serviceable areas. At time of
            redemption the Customer will be required to provide full delivery
            address.
          </li>
          <li>
            All redeemable products selected for delivery by Customer for
            redemption, shall be delivered within India as per instructions of
            the Customer through our/Daqi’s logistics partners. All deliveries
            where applicable shall be made on a best efforts basis, and while
            Augmont/Daqi will endeavor to deliver the products on the dates
            intimated, Augmont/Daqi disclaims any claims or liabilities arising
            from any delay in this regard.
          </li>
          <li>
            The logistics partner will make a maximum of three attempts to
            deliver Your order. After three attempts gold coin will be delivered
            back to Augmont and Customer will need to put the fresh request for
            the redemption.
          </li>
          <li>
            Augmont/Daqi shall not be liable / responsible, in any manner
            whatsoever, for any loss / liability arising out of or relating to
            any technical failure / issue in Platform and / or acts / omission
            not attributable to Augmont/Daqi.
          </li>
          <li>
            Notwithstanding anything contrary contained herein, the service, the
            interface and API work, and their respective information, pricing
            and data, and availability are subject at any time and from time to
            time to human, mechanical, typographic, or other errors, oversights,
            mistakes, limitations, delays, service interruptions, including,
            without limitation, as may be due in whole or in part to, related to
            or arising out of (i) computer hardware and software,
            telecommunication and operating systems, databases, or business
            processes and procedures, other problems inherent in, or which may
            be associated with, the use of the internet and electronic
            communications including, without limitation, force majeure event ,
            government / regulatory actions, orders, notifications etc. and / or
            and acts and omissions of third parties etc. affecting or impacting
            the service, the interface or the API work, its information and
            data, or such communications. Customer acknowledges and agrees that
            Daqi is not responsible or liable whatsoever for delays, failures,
            or other loss due to, caused by or resulting from any such problems,
            in whole or in part. If your GAP account is over credited or
            wrongfully credited / debited, then Daqi has right to reverse /
            cancel without notice or require cancellation / reversal of such
            transaction at its sole discretion and debit / credit the gold to /
            from your GAP account, as the case may be. Daqi shall not be liable
            for any wrong transfer of Gold by you to another GAP account
            (including but not limited to transfer to a different user /
            customer etc.) and the gold grams shall not be reversed to you in
            case of an erroneous transfer and / or in case the transferee has
            already transacted the Gold transferred.
          </li>
          <li>
            Upon Augmont confirming the purchase, redemption, sale-back of Gold
            in his / her GAP account by Customer, the order / request of
            Customer to purchase / redemption / sale-back / transfer, as the
            case may be, shall be binding on the Customer and cannot be
            cancelled.
          </li>
          <li>
            The Customer shall inform immediately, in any case no later than
            [30] days of the transaction, of any irregularities or discrepancies
            that may exist in his/her GAP account, failing which it shall be
            deemed that there is no error or discrepancies in the GAP account.
            All records maintained by Augmont/Daqi, in electronic or documentary
            form of the instructions of the Customer and such other details
            (including, but not limited to payments made or received) pursuant
            to the Terms, shall as against the Customer, be deemed to be
            conclusive evidence of such instructions.
          </li>
          <li>
            The Customer understands and acknowledges that any purchase,
            redemption, sale-back or transfer from / to GAP account will be on
            instructions received by the Customer, and the Customer will not
            violate any applicable laws or regulations for the time being in
            force in or outside India. The Customer shall be responsible for
            complying with applicable laws in respect of purchase, redemption,
            sale-back to and from the GAP account including but not limited to
            the Prevention of Money Laundering Act, 2002, the Prohibitions of
            Benami Property Transactions Act, 1998, Income Tax Act, 1961 etc
            including amendment thereof. Customer also agrees and undertakes
            that he /she will not open any account/GAP account in the name of
            any minor. Augmont/Daqi will not be liable in any manner in this
            regard.
          </li>
          <li>
            The Customer feedbacks pertaining to products shall be deemed to be
            non-confidential and non-compensatory in nature. Augmont/Daqi
            reserves the right, at its sole discretion to use such information
            for its internal purposes.
          </li>
          <li>
            In the event of termination / expiry of Augmont’s arrangement with
            Daqi or otherwise at any point of time, Augmont and/or Daqi may
            notify You to sell back Your Gold balance to Augmont or migrate Your
            GAP account to an alternative platform and continue with GAP account
            on such alternative platform (as decided by Augmont/Daqi).
          </li>
          <li>
            These Terms shall be governed by and interpreted and construed in
            accordance with the laws of India. The courts in Delhi shall have
            exclusive jurisdiction in respect of any matters arising therefrom.
          </li>
          <li>
            In the event any dispute arises out of or in connection with the
            Terms herein, including the validity hereof, the parties hereto
            shall endeavor to settle such dispute amicably in the first
            instance. The attempt to bring about an amicable settlement shall be
            treated as having failed as soon as one of the parties hereto, after
            reasonable attempts, which shall continue for not less than 15
            (Fifteen) calendar days, gives a notice to this effect, to the other
            party in writing.
          </li>
          <li>
            <b>Waiver:</b> Any failure or delay by Daqi/Augmont to enforce or
            exercise any provision of these Terms, or any related right, shall
            not constitute a waiver by Daqi/Augmont of that provision or right.
            The exercise of one or more of a Daqi/Augmont’s rights hereunder
            shall not be a waiver of, or preclude the exercise of, any rights or
            remedies available to Augmont/Daqi under these Terms or in law or at
            equity. Any waiver of any provision shall only be effective if made
            in writing and executed by a duly authorized officer of
            Augmont/Daqi.
          </li>
          <li>
            <b>Force Majeure:</b> If performance under these Terms by
            Daqi/Augmont is prevented, restricted, delayed or interfered with by
            reason of labor disputes, strikes, acts of God, floods, lightning,
            severe weather, shortages of materials, rationing, inducement of any
            virus, Trojan or other disruptive mechanisms, any event of hacking
            or illegal usage of the Platform, utility or communication failures,
            earthquakes, war, revolution, acts of terrorism, civil commotion,
            acts of public enemies, blockade, embargo or any law, order,
            proclamation, regulation, ordinance, demand or requirement having
            legal effect of any government or any judicial authority or
            representative of any such government, or any other act whatsoever,
            whether similar or dissimilar to those referred to in this clause,
            which are beyond the reasonable control of the Daqi/Augmont and
            could not have been prevented by reasonable precautions then the
            Augmont/Daqi shall in to be excused and discharged from such
            performance to the extent of and during the period of such force
            majeure event, and such non-performance shall, in no manner
            whosoever, amount to a breach by the Daqi/Augmont of its obligations
            herein.
          </li>
          <li>
            <b>Absence of relationship:</b> You represent and warrant to
            Daqi/Augmont that You have sufficient experience and knowledge to
            make informed decisions to purchase/ redeem/ sale-back
            Gold/Redeemable Products. You acknowledge that You are making all of
            Your own decisions in connection with purchases or redemption or
            sale-back and that You have not relied on any information made
            available by Daqi/Augmont, and that Daqi/Augmont is not making any
            recommendation with respect to such purchases/redemption/sale-back/
            of Gold/redeemable products. No relationship other than
            seller-purchaser, including, without limitation, any agent-principal
            relationship, any advisor-advisee relationship, any
            employee-employer relationship, any franchisee-franchisor
            relationship, any joint venture relationship or any partnership
            relationship, exists between You and Augmont. Further, these Terms
            do not constitute any agent-principal relationship, any
            advisor-advisee relationship, any employee-employer relationship,
            any franchisee-franchisor relationship, any joint venture
            relationship or any partnership relationship between You and Daqi.
          </li>
          <li>
            <b>Electronic Order Risks:</b> Order entry systems have been
            designed to provide an efficient and dependable method for entering
            orders. Commercial internet service providers are not 100% reliable
            and a failure by one or more of these providers may affect
            internet-based order entry. You acknowledge that the order entry
            system is an electronic mechanical system and as such may be subject
            to failure beyond the control of Daqi/Augmont. Therefore,
            Daqi/Augmont shall not be responsible for errors, negligence,
            inability to execute orders, delays in transmission, delivery or
            execution of order due to breakdown or failure of transmission or
            communication facilities, or to any other cause beyond
            Daqi/Augmont’s control or anticipation. You acknowledge that pricing
            or typographical errors may occur and that therefore, in the event
            that a product is listed at an incorrect price or with incorrect
            information due to an error in pricing or product information,
            Daqi/Augmont reserves the right, at our sole discretion, to refuse
            or cancel or reject any orders placed for the products. Furthermore,
            Augmont/Daqi reserves the right to deny refuse or cancel or reject
            any orders placed in case of market volatility and/ or unusual
            circumstances or conditions. This includes, without limitation,
            unexpected unavailability of product/Gold.
          </li>
          <li>
            <b>Customer responsibility for taxes, tariffs and duties:</b> You
            acknowledge that You are solely responsible for all taxes, tariffs
            and duties that may be incurred as a result of purchase/redemption/
            of Gold/redeemable products. Daqi/Augmont will only collect taxes,
            tariffs and duties on transactions to the extent explicitly required
            by law. It is Your responsibility to be aware and properly address
            any taxes, tariffs and duties to which You may be subject by any
            local, state and/ or federal governments. If You have any questions
            about taxes, tariffs and duties, You should consult a tax or other
            professional about Your unique circumstances. Daqi/Augmont does not
            represent to provide advice or recommendations about Your specific
            taxes, tariffs and duties and any statements made by us are only for
            the purpose of raising issues for You to discuss with Your tax or
            other professional.
          </li>
          <li>
            <b>Indemnification:</b> Customer hereby agrees to indemnify and keep
            Daqi indemnified from and against all actions, claims, demands,
            proceedings, losses, damages, personal injury, costs, charges and
            expenses, directly or indirectly, whatsoever (‘Losses’) which Daqi
            or its employees, agents, workers or representative may at any time
            incur, sustain, suffer or be put to as a consequence of or by reason
            of or arising out of:
            <ol>
              <li>the usage of the Platform by the Customer.</li>
              <li>
                by reason of Augmont ‘s acting in good faith and taking or
                refusing to take or omitting to take action on the Customer’s
                instructions, and in particular arising directly or indirectly
                out of the negligence, mistake or misconduct of the Customer.
              </li>
              <li>
                breach or noncompliance of the Terms and relating to the GAP
                account and/or
              </li>
              <li>
                fraud or dishonesty relating to any transaction by the Customer.
                Without prejudice to the foregoing, Daqi shall be under no
                liability whatsoever to the Customer in respect of any loss or
                damage arising directly or indirectly out of: (i) Effecting
                transaction based on instructions received from Customers, (ii)
                The instruction of a Customer to transfer Gold from his / her
                GAP to another GAP, (iii) The exercise by Augmont of its right
                to terminate / exercise the facility/services provided on the
                Platform, (iv) Any injury to the credit, character and
                reputation of the Customer due to use of facility/ services on
                the Platform, (v) Any misstatement, misrepresentation, error or
                omission in any details disclosed by Daqi if it receives any
                process, summons, order, injunction, execution distrait, levy
                lien, information or notice which Daqi in good faith believes/
                calls into question the Customer’s ability, or the ability of
                someone purporting to be authorized by the Customer, to make the
                transfer.
              </li>
            </ol>
          </li>
          <li>
            Augmont/Daqi may, at its option and without liability to the
            Customer or such other person, decline to allow the Customer to
            obtain any portion of his gold/ product, or may handover such
            gold/product over to an appropriate authority and take any other
            steps required by applicable law.
          </li>
          <li>
            Disclaimers and limits of liability: Daqi does not make any
            representations, recommendations, projections, warranties or
            guarantees of any kind, either expressed or implied, as to the
            performance of the Gold/Redeemable Products with respect to future
            prices or any return on investment. Daqi will not be liable to any
            Customer (including but not limited to Transferee) for any losses
            caused by or relating to transfer/deposit of Gold to GAP account of
            any other Customer. Daqi explicitly urges to all its Customers to
            inspect the package with the gold product (“Package”) for any damage
            or tampering before receiving or signing for receipt. Any complaint
            / issue with respect to the quantity, make, quality etc., of the
            product/gold product delivered to a Customer(s) must be raised by
            the Customer(s) within a period of 7 days from the date of delivery.
            Daqi/Augmont will not be liable for any loss or damage caused if the
            Customer accepts Package in spite of it being damaged or tampered.
            If You find any tampering, do not accept Package and return the
            same. The duty to verify the Package for any damages or tampering is
            of the Customer and if the Customer accepts the Package and later
            comes to know of any damage or tampering, Daqi/Augmont shall not be
            liable for any loss or damage to Customer. If such loss is later
            reported and approved by logistics partner and insurance company,
            the limit of liability shall be restricted to the amount paid by the
            logistic partner for such loss or damage in transit as per their
            terms and conditions and the same shall be binding upon the
            Customer.
          </li>
          <li>
            You acknowledge that Daqi will not be liable to You or any other
            person for indirect, consequential, special, incidental, punitive,
            or exemplary damages, including, without limitation, lost profits,
            lost savings and lost revenues (collectively, the “excluded
            damages”), whether or not characterized in negligence, tort,
            contract, or other theory of liability. In no event will Daqi or any
            of its directors, employees total liability to You for all damages,
            losses, liabilities and causes of action under these Terms and the
            facility/ services provided by Daqi collectively exceed total amount
            paid by You, if any, for access to that particular products or
            services. The foregoing limitation of liability shall apply to the
            fullest extent permitted by law in the applicable jurisdiction.
          </li>
          <li>
            You expressly agree that use of the Platform is at your sole risk.
            While we/Daqi will take all reasonable precautions to ensure
            uninterrupted and error-free operations, your access and use of the
            Platform is at your sole risk. The service is provided on an “as is”
            and “as available” basis. We/Daqi and our licensors, suppliers,
            vendors, parent, holding, subsidiary and related companies,
            affiliates, officers, agents and employees expressly disclaim all
            warranties of any kind, whether express or implied, including, but
            not limited to the implied warranties of merchantability, fitness
            for a particular purpose and non-infringement. In no event shall
            Augmont/Daqi be liable for any direct, indirect, incidental,
            special, punitive, consequential damages, or any damages whatsoever,
            including, but not limited to, damages for loss of profits,
            goodwill, use, data, or other intangible losses resulting from:
            <ol>
              <li>
                The use or the inability to use our services or access content,
              </li>
              <li>
                The cost of procurement of substitute goods and services
                resulting from transactions entered into through or from the
                Platform,
              </li>
              <li>
                The unauthorized access to or alterations of your transmissions
                or data,
              </li>
              <li>
                Statements or conduct of any third party on the service, or
              </li>
              <li>
                Any other matter relating to any service or product offered,
                delivered or agreed to be delivered, even if Augmont/One 97 has
                been advised of the possibility of damages.
              </li>
            </ol>
          </li>
          <li>
            You declare that You are an individual not having registration and
            neither required to take registration under Central Goods and
            Service Tax Act 2017, State Goods and Service Tax Act 2017,
            Integrated Goods and Service Tax Act 2017 and Union Territory Goods
            and Service Tax Act 2017.
          </li>
          <li>
            You declare that gold You are selling was previously owned by You
            for personal use.
          </li>
          <li>
            You declare that You are not an unregistered dealer of gold and
            silver or any other precious metal ornament or any related product.
          </li>
          <li>
            If there has been any misrepresentation by You, You would be solely
            responsible for all types the tax, interest and penalty due to the
            government at any given point of time.
          </li>
          <li>
            Except where noted otherwise, the prices displayed for products on
            the Platform represents the value of the respective product. For
            certain transactions, you shall bear fees and charges in addition to
            payment for prices displayed on the Platform. Such additional fees
            and charges shall be as are stated on the Platform. Please pay
            attention to the details of your transactions, because your total
            price for a transaction may include taxes, fees and shipping costs,
            which you are responsible for paying.
          </li>
        </ol>
        <h4>Promotional Goldback Terms & Conditions</h4>
        <ol>
          <li>
            If you already have a Gold Accumulation Plan Account (GAP) with
            required Gold seller (MMTC-PAMP for 24k 99.99% Purity Gold & Augmont
            for 24k 99.90% Purity Gold), Gold will be credited in that account
            otherwise a new GAP account will be created for you. GAP accounts
            are offered and created by MMTC-PAMP/Augmont.
          </li>
          <li>
            Successful application of Goldback promo code would authorize Daqi
            to place an order for you with required Gold seller for the
            promotional amount due to you as Cashback. The invoice of such an
            order would bear your name and would be useful for tax purposes.
          </li>
          <li>
            If Pin Code is already provided in the GAP account then that will be
            used otherwise the new account would assume a Pin Code – 122103 for
            MMTC-PAMP’s GAP account and 400003 for Augmont’s GAP account. These
            are based on Gold seller’s location.
          </li>
          <li>
            3% GST would be applicable on the purchase of Gold from MMTC
            PAMP/Augmont and would be paid from the cashback amount due to you.
          </li>
          <li>
            In case you want to sell your Gold, you will have to provide your
            bank account number to which funds can be credited.
          </li>
          <li>
            In case the Gold can’t be credited to your GAP account due to KYC or
            any other reason, Daqi will credit Cashback in your Daqi wallet in
            case of such failures.
          </li>
          <li>
            The price of gold used is the Buy price plus applicable taxes at the
            time of credit of Gold to your GAP account.
          </li>
          <li>
            Buy Price of Gold can be different from the Sell Price of Gold and
            Daqi is not liable for price difference and price movements of Your
            Gold
          </li>
          <li>
            Gold will be credited within 24-48 hours of a successful
            transaction.
          </li>
          <li>
            In case of any cancellations and returns of Your order, value of
            Gold as per the promotional campaign amount would be adjusted from
            the refund amount, if any. Gold bought on Your behalf under this
            scheme would not be returned or refunded.
          </li>
          <li>
            Gold transaction can be seen under ‘Gold Passbook’ when you click on
            Gold icon on Daqi App and visit ‘Locker’. Please upgrade to latest
            App version first
          </li>
          {/* <li>
            For details and FAQ on Daqi gold please visit
            https://Daqi.in/care/Daqi-gold-faq/
          </li>
          <li>
            For additional T&Cs please visit
            https://Daqi.in/offer/goldbackdetailed/
          </li> */}
        </ol>
        <h4>Please read these terms of use carefully.</h4>
        <p>
          By accessing the Daqi Platform and agreeing to initiate/opt for Gold
          Savings Plan, you agree to be bound by the terms of use below. These
          terms of use are subject to change. Any changes will be incorporated
          into the terms of use posted to Daqi Platform from time to time. If
          you do not agree with these terms of use, please do not
          access/initialize/opt-for Gold Savings Plan.
        </p>
        <h4>Dispute Resolution</h4>
        <p>
          If any dispute, controversy or claim arises under this Agreement or in
          relation to any Daqi Service or the Daqi Platform, including any
          question regarding the existence, validity or termination of this
          Agreement or T&Cs (hereinafter Dispute), the parties shall use all
          reasonable endeavours to resolve such Dispute amicably. If the parties
          are unable to resolve the Dispute amicably within 30 days of the
          notice of such Dispute, Daqi may elect to resolve any Dispute by a
          binding arbitration in accordance with the provisions of the Indian
          Arbitration & Conciliation Act, 1996 (hereinafter Act). Such Dispute
          shall be arbitrated on an individual basis and shall not be
          consolidated in any arbitration with any claim or controversy of any
          other party. The Dispute shall be resolved by a sole arbitrator,
          appointed in accordance with the Act. The seat of the arbitration
          shall be New Delhi and the language of this arbitration shall be
          English. Either You or Daqi may seek any interim or preliminary relief
          from a court of competent jurisdiction in New Delhi necessary to
          protect the rights or the property belonging to You or Daqi (or any of
          our agents, suppliers, and subcontractors), pending the completion of
          arbitration. Any arbitration shall be confidential, and neither You
          nor Daqi may disclose the existence, content or results of any
          arbitration, except as may be required by law or for purposes of
          enforcing the arbitration award. All administrative fees and expenses
          of arbitration will be divided equally between You and Daqi. In all
          arbitrations, each party will bear the expense of its own lawyers and
          preparation. This paragraph shall survive termination of this
          Agreement.
        </p>
        <h4>Governing Law and Forum for Disputes</h4>
        <p>
          Subject to the Dispute Resolution section above, You agree that any
          claim or dispute You may have against Daqi must be resolved by a court
          having jurisdiction in Mumbai, India. You agree to submit to the
          personal jurisdiction of the courts located within Mumbai, India, for
          the purpose of litigating all such claims or disputes. This Agreement
          shall be governed by Indian law. This paragraph shall survive
          termination of this Agreement.
        </p>
      </div>
    </>
  );
};

export default TermsAndConditions;
