import React, { useState } from "react";
import { Link } from "react-router-dom";
const Header = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const closeMenu = () => {
    setIsActive(false);
  };
  return (
    <>
      {/* ---------------- Mobile Header ---------------------------------- */}
      <header className="navHeaderMobile">
        <div className="ourContainerMobile">
          <nav className="nav-bar">
            <Link to="/" className="navbar-brand">
              <img
                src="assets/img/daqi_logo.png"
                alt="Logo"
                style={{ height: "40px" }}
              />
            </Link>

            <ul className={`nav-menu ${isActive ? "active" : ""}`}>
              <li className="nav-item">
                <Link to="/" className="nav-link" onClick={closeMenu}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/buy-products-on-credit"
                  className="nav-link"
                  onClick={closeMenu}
                >
                  Buy products on credit
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact-us" className="nav-link" onClick={closeMenu}>
                  Contact us
                </Link>
              </li>
              {/* <li className="nav-item">
                <button
                  className="btn btn-md"
                  style={{
                    backgroundImage:
                      "linear-gradient(to bottom, #f7674c, #f47746)",
                    color: "White",
                    width: "170px",
                    borderRadius: "1",
                  }}
                >
                  Pay EMI Now
                </button>
              </li> */}
            </ul>

            <div className="hamburger" onClick={toggleMenu}>
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </div>
          </nav>
        </div>
      </header>
      {/* ---------------- Mobile Header ---------------------------------- */}
      <header className="navbar navbar-expand-lg navbar-light navbarCustomChange">
        <div className="container">
          <Link to="/" className="navbar-brand" >
            <img
              src="assets/img/daqi_logo.png"
              alt="Logo"
              style={{ height: "40px" }}
            />
          </Link>
          <div className="navbar-nav mx-auto">
            <Link to="/" className="nav-link px-4">
              Home
            </Link>
            <Link to="/buy-products-on-credit" className="nav-link px-4">
              Buy products on credit
            </Link>
            <Link to="/contact-us" className="nav-link px-4">
              Contact us
            </Link>
          </div>
          {/* <button
            className="btn btn-md payNowButton"
          >
            Pay EMI Now
          </button> */}
        </div>
      </header>
    </>
  );
};

export default Header;
