import React from "react";

const AboutUs = () => {
  return (
    <>
      <div className="grievance-page">
        {/* <h3 style={{ textAlign: "center" }}>About Us</h3> */}
        <p>
          <b style={{ fontSize: "18px" }}>
            Daqi is a tech-enabled platform that facilitates building
            construction materials and FMEG (Fast Moving Electrical Goods) with
            embedded purchase credit. The platform integrates merchants/ shops
            and manufactures/suppliers for key materials at better price and
            convenience.
          </b>
          <br />
          <br />
          <p style={{ fontSize: "18px" }}>
            We have partnered with multiple Non-Banking Finance Companies (NBFC)
            to offer purchase credit to its merchants/shops.
          </p>
          <b>Benefits to shops for working with Daqi</b>
          <ul className="aboutus_page">
            <li>
              Better prices with convenience, we guarantee discounted rates from
              market
            </li>
            <li>
              Convenience of order (app, website, WhatsApp, call or in person)
            </li>
            <li>
              Hassle-free next day delivery: we cover more than 6500 pins across
              India
            </li>
            <li>Instant credit up to Rs. 5 lacs with no hidden fees</li>
          </ul>
        </p>
        <p>
          If you are highly motivated and passionate about building business,
          please send your resume, we are hiring across sales, technology, brand
          partnership Collections and operations.
          <br />
          <b>
            Email: <a href="mailto:Support@daqi.in">Support@daqi.in</a>
          </b>
          <br />
          [9:30 AM to 6:30 PM, Monday to Friday except Public Holidays]
        </p>
        <b>Third Party Vendors and Agencies</b>
        <ul className="aboutus_page">
          <li>Digitap </li>
          <li>Signzy </li>
          <li>Finezza</li>
          <li>E-Mudra</li>
          <li>Zensum Solutions</li>
          <li>AWS</li>
          <li>Cashfree</li>
          <li>Equifax</li>
          <li>Trai</li>
        </ul>
      </div>
    </>
  );
};

export default AboutUs;
