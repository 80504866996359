import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
ReactModal.setAppElement('#root');

const Popup = ({ isOpen, onRequestClose, setIsOpen, imageUrl }) => {


    useEffect(() => {
        if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
            setIsOpen(true);
        }
    }, []);

    return (
        <>
            <div className='modalMainContainer'>
                <ReactModal
                    isOpen={isOpen}
                    onRequestClose={onRequestClose}
                    className="modal-content"
                >
                    <div style={{ position: "relative", textAlign: "center" }}>
                        <span className="modal-close" onClick={onRequestClose}>&times;</span>
                        <img src={imageUrl} alt="Popup Banner" className="centered-image" />
                    </div>
                </ReactModal>
            </div>
        </>

    );
};

export default Popup;