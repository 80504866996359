import React from "react";

const FirstSection = () => {
  return (
    <section className="credit_page2">
      <div className="container">
        <div className="row firstsection">
          <div className="col-md-7">
            <div className="col-md-11 catlongSectionFirst">
              <div className="d-flex flex-column justify-content-center">
                <h1 className="topHeadingFisConstru">
                  <span className="buyFmegFirstSpan">
                    Connect FMEG/building construction material shops{" "}
                    <span className="buyFmegSecondSpan">
                      with distributors and manufacturers{" "}
                    </span>
                  </span>
                </h1>
                <p className="withEmbeddedPara">
                  With more than 50+ brands on platform, shops can buy
                  <br />
                  with convenience and embedded ‘credit’ upto Rs. 10 lacs
                  <br />
                </p>
                <div className="mb-3 startAndGoogleIcon">
                  <button className="btn btn-md learnMoreChangebtn">
                    Buy Product on Credit
                  </button>
                  <button className="btn btn-md fw-bold bulk_order_button">
                    Bulk orders on platform{" "}
                  </button>
                  <p className="coming_soon">coming soon </p>
                  <div className="forResponsive">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.daqi.in"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="google_play_credit_page"
                        src="assets/img/credit_page/googlePlay.png"
                        alt="Get it on Google Play"
                      />
                    </a>
                    <br />
                    <img
                      className="rating"
                      src="assets/img/credit_page/rating.png"
                      alt="Get it on Google Play"
                    />
                    <span> Based on 10,000+ reviews on</span>
                    <br />
                    <div className="appicons">
                      <img
                        src="assets/img/credit_page/small_google_play.png"
                        alt="Get it on Google Play"
                      />{" "}
                      &nbsp;
                      <img
                        src="assets/img/credit_page/capterra.png"
                        alt="Get it on Google Play"
                      />{" "}
                      &nbsp;
                      <img
                        src="assets/img/credit_page/getapp.png"
                        alt="Get it on Google Play"
                      />{" "}
                      &nbsp;
                      <img
                        className="trustRadius"
                        src="assets/img/credit_page/tr-blue-flat.png"
                        alt="Get it on Google Play"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 firstSection_secondcolumn">
            <img
              className="credit_banner_image"
              src="assets/img/credit_page/credit_banner.png"
              alt="sell_page"
            />
            {/* <img
            className="sell_page_image"
            src="assets/img/credit_page/round_curved.png"
            alt="sell_page"
          /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FirstSection;
