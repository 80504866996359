import React from "react";

const FAQ = () => {
  return (
    <>
      <div className="grievance-page">
        <h3 style={{ textAlign: "center", fontWeight: "700" }}>
          FAQ's (Frequently Asked Questions)
        </h3>
        <br />
        <h5>A. Pre-order: Queries before placing your order</h5>
        <h5>B. Post-order: Queries regarding an already placed order</h5>
        <hr />
        <div>
          <h5 style={{fontWeight:700}}>1. How to place orders in Daqi?</h5>
          <p>To begin ordering, you have to download and install Daqi mobile application from Indus App store.</p>
          <div>
            <h4>Steps to place an order:</h4>
            <ol>
                <li>1. First you have to upload an image which products you have to order.</li>
                <li>2. Merchant will check and confirm the order according to your quotation.
                </li>
              <li>3. After the confirmation from the merchant you can pay and get the order.</li>
              <li>4. You can see your order's in the order history tab.</li>
            </ol>
          </div>
        </div>
        <div>
          <hr />
          <h5 style={{fontWeight:700}}>2. How to change quantity of orders in the cart?</h5>
          <p className='faqhightlight'>To change the quantity for items you have not yet added to the cart -</p>
          <p>Once you click on the "Add to Cart" button and add the item to your shopping cart, enter the desired quantity in the "Quantity" dropdown option on the right side of the page.</p>
          <p className='faqhightlight'>To change the quantity for items already added to the cart -</p>
          <p>Go to Cart, click on the quantity dropdown box to the right of the title and choose the desired quantity. The quantity number for the item and order amount will be updated automatically.</p>
          <p>If the entered quantity is not available with us, you'll see an error message.</p>
        </div>

        <div className="">
          <hr />
          <h5 style={{fontWeight:700}}>3. How can I register as a merchant on the platform?</h5>
          <p>You can register as a merchant by visiting our mobile app and completing the registration form with your business details. Verification might take up to 24-48 hours.
          </p>
        </div>

        <div>
          <hr />
          <h5 style={{fontWeight:700}}>4. Why am I unable to place orders?</h5>
          <p>This could be due to the following issues:</p>
          <ul>
            <li>1. Check if there is a Payment decline. Please click here to know more about Payment issues.</li>
            <li>2. The item you have selected is out of stock.</li>
            <li>3. There is some technical or internet connectivity issue.</li>
            <li>4. Undeliverable to selected location/pincode.</li>
            <li>5. Check if there is a quantity limit.</li>
            <li>6. Sometimes when FBA and seller fulfilled items are clubbed together.</li>
            </ul>
        </div>

        <div>
          <hr />
          <h5 style={{fontWeight:700}}>5. Can I request an increase in my credit limit?</h5>
          <p>Yes, you can request a credit limit increase by contacting our support team. 
            Requests will be reviewed based on your transaction history and payment behavior.
          </p>
        </div>

        <div>
          <hr />
          <h5 style={{fontWeight:700}}>6. How do I place an order using my credit limit?</h5>
          <p> Once your account is approved and active, you can place orders with distributors directly through our platform, and the amount will be deducted from your available credit.
          </p>
        </div>

        <div>
          <hr />
          <h5 style={{fontWeight:700}}>7.  Can I use the credit for multiple orders?</h5>
          <p>Yes, you can use your credit limit for multiple orders as long as the total amount does not exceed to available credit.
          </p>
        </div>

        <div>
          <hr />
          <h5 style={{fontWeight:700}}>8. How and when do I need to repay the credit?</h5>
          <p>You need to repay the credit once your credit limit is exhausted or as per the repayment terms agreed upon during registration. 
            Details will be available in your account dashboard.
          </p>
        </div>

        <div>
          <hr />
          <h5 style={{fontWeight:700}}>9. What happens if I am unable to repay the credit on time?</h5>
          <p> If you are unable to repay on time, please contact our support team immediately to discuss possible solutions.
             Late payments might attract penalties as per our terms and conditions.
          </p>
        </div>

        <div>
          <hr />
          <h5 style={{fontWeight:700}}>10. How can I check my available credit balance?</h5>
          <p> You can check your available credit balance by logging into your account on our platform. 
            The balance will be displayed on your dashboard.
          </p>
        </div>

        
        
        <div>
          <hr />
          <h5 style={{fontWeight:700}}>11. What should I do if I encounter an issue with my order?</h5>
          <p>  If you encounter any issues with your order, please reach out to our support team immediately. We will work with the distributor to resolve the issue as quickly as possible.
          </p>
        </div>

        <div>
          <hr />
          <h5 style={{fontWeight:700}}>12. Is my information secure on the platform?</h5>
          <p>Yes, we use industry-standard security measures to protect your personal and business information. 
            Your data is encrypted and stored securely.
          </p>
        </div>

      </div>
    </>
  );
};

export default FAQ;
