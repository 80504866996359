import React from "react";

const ThirdSection = () => {
  return (
    <section className="buyProdectCreditSection">
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center text-white">
            <div>
              <h1 className="product-with-daqi">
                Buy product on credit with Daqi
              </h1>
              <p style={{ color: "white", lineHeight: 2 }}>
                ✓ No interest or hidden charges
                <br />
                ✓ Purchase limit upto Rs. 5 lacs <br />
                ✓ Convenient credit with low prices (order on app, call,
                website, or in person) <br />
                ✓ Easy repayment options <br />
                ✓ Hassel free delivery of material <br />✓ No collateral needed
              </p>
            </div>
            <a
              href="https://play.google.com/store/apps/details?id=com.daqi.in"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="assets/img/credit_page/googlePlay.png"
                alt="Get it on Google Play"
                height={90}
                className="googleStoreIcon"
              />
            </a>
          </div>
          <div className="col-md-6 d-flex align-items-center imageGoogleWithMen">
            <div style={{ position: "relative" }}>
              <img
                src="assets/img/diagonal2.png"
                alt="personImage"
                height={400}
              />
              <img
                className="logoBrandIcon"
                src="assets/img/logo_icon.png"
                alt="personImage"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThirdSection;
