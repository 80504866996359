import React from "react";

const WorkFlow = () => {
  return (
    <div
      className="container-fluid"
      style={{ marginTop: "50px", marginBottom: "50px" }}
    >
      {/* First Row */}
      <div className="row mb-4 justify-content-center">
        <div className="col text-center">
          <h1>
            <span
              style={{ color: "#12264F", fontWeight: "600", fontSize: "30px" }}
            >
              How it Works
            </span>
          </h1>
          <p style={{ color: "#FC5952", fontWeight: "600", fontSize: "17px" }}>
            Enhance Your Sales And Inventory With Daqi
          </p>
        </div>
      </div>
      <div className="row justify-content-center mt-4 mb-4">
        <div className="col text-center">
          <img
            className="workFlowImageDesktop"
            src="assets/img/mobileSection.png"
            alt="mobileSection"
          />
          {/* ---------------- for Mobile ------------------------- */}
          <img
            className="workFlowImageMobile"
            src="assets/img/mobileGroup.png"
            alt="mobileSection"
          />
          {/* ---------------- for Mobile ------------------------- */}
        </div>
      </div>
    </div>
  );
};

export default WorkFlow;
