import React from "react";

const BrandsSection = () => {
  return (
    <section className="brandClientSection">
      <div className="container containercustom">
        <div className="heading-box">
          <h2 className="text-center mb-4 mt-4 py-2">Brands we work with*</h2>
          <div className="box"></div>
        </div>
        <div className="row">
          <div className="col-md-3 col-xs-6 mb-4">
            <div className="text-center">
              <img
                src="assets/img/polycab.png"
                alt="Polycab"
                className="brandImageName"
              />
            </div>
          </div>
          <div className="col-md-3 col-xs-6 mb-4">
            <div className="text-center">
              <img
                src="assets/img/Havells.png"
                alt="Havells"
                className="brandImageName"
              />
            </div>
          </div>
          <div className="col-md-3 col-xs-6 mb-4">
            <div className="text-center">
              <img
                src="assets/img/cromptonimage.png"
                alt="Company 3"
                className="brandImageName"
              />
            </div>
          </div>
          <div className="col-md-3 col-xs-6 mb-4">
            <div className="text-center">
              <img
                src="assets/img/Starline.png"
                alt="Company 4"
                className="brandImageName"
              />
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-3 col-xs-6 mb-4">
            <div className="text-center">
              <img
                src="assets/img/precision.png"
                alt="Company 5"
                className="brandImageName"
              />
            </div>
          </div>
          <div className="col-md-3 col-xs-6 mb-4">
            <div className="text-center">
              <img
                src="assets/img/kei.png"
                alt="Company 6"
                className="brandImageName"
              />
            </div>
          </div>
          <div className="col-md-3 col-xs-6 mb-4">
            <div className="text-center">
              <img
                src="assets/img/legrand2.png"
                alt="Company 7"
                className="brandImageName"
              />
            </div>
          </div>
          <div className="col-md-3 col-xs-6 mb-4">
            <div className="text-center">
              <img
                src="assets/img/finolexpip.png"
                alt="Company 8"
                className="brandImageName"
              />
            </div>
          </div>
        </div>
        <div className="row text-center ">
          <div className="col-md-12 ">
            <button
              className="btn btn-light btn-lg btn-block"
              style={{
                border: "2px solid #FC5952",
                color: "#FC5952",
                borderRadius: "0",
                fontWeight: "600",
                fontSize: 19,
                background: "none",
                marginTop: "52px",
              }}  
            >
              Learn More
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BrandsSection;
