import React from "react";

const GrievanceRedressal = () => {
  return (
    <>
      <div className="grievance-page">
        <h3>CONTACT GRIEVANCE OFFICER</h3>
        <p>
          Please contact our Customer Care Department and/or Grievance Officer -
          Santosh Pandey with any questions or concerns regarding this Privacy
          Notice and/or security by writing an email on: Support@daqi.in If you
          have any questions or concerns regarding our notice, or if you believe
          our notice or applicable laws relating to the protection of your
          personal information have not been respected, you may file a complaint
          with our Privacy Department listed above, and we will respond to let
          you know who will be handling your matter and when you can expect a
          further response. We may request additional details from you regarding
          your concerns and may need to engage or consult with other parties in
          order to investigate and address your issue. We may keep records of
          your request and any resolution.
        </p>
        <p>
          <b>Name: Ms. Anjali</b>
          <br />
          <b>Address: Ptolemy Technology Private Limited, Mumbai, India.</b>
          <br />
          <b>
            Email: <a href="mailto:Support@daqi.in">Support@daqi.in</a>
          </b>
          <br />
          [9:30 AM to 6:30 PM, Monday to Friday except Public Holidays]
        </p>
      </div>
    </>
  );
};

export default GrievanceRedressal;
