import React from "react";

const FourthSection = () => {
  return (
    <>
      <div className="fourth_container">
        <img
          className="rectangle_image"
          src="assets/img/credit_page/rectangle.png"
          alt="rectangle"
        />
        <img
          className="daqi_logo_image"
          src="assets/img/credit_page/daqi_logo_image.png"
          alt=""
        />
        <a
          href="https://play.google.com/store/apps/details?id=com.daqi.in"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="logo_image"
            src="assets/img/credit_page/googlePlay.png"
            alt=""
          />
        </a>
        <h3 className="heading_tag">
          Download The Daqi App Now From Google Play Store <br />
          Bulk Orders On DAQI Ordering Platform
        </h3>
      </div>
    </>
  );
};

export default FourthSection;
