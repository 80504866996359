import React from "react";

const CardSection = () => {
  return (
    <div className="container cardSectionInformation">
      {/* First Row */}
      <div className="row mb-4 justify-content-center">
        <div className="col text-center ourChangeWith">
          <h1>
            <span
              style={{ color: "#12264F", fontWeight: "600", fontSize: "30px" }}
            >
              Benefits to shops
            </span>
          </h1>
          <p style={{ color: "#FC5952", fontWeight: "600", fontSize: "17px" }}>
            Working With Daqi
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 mb-5">
          <div className="card border-0">
            <img
              src="assets/img/credit.png"
              className="img-fluid"
              alt="prices"
              height={70}
              width={70}
            />
            <div className="card-body">
              <h5 className="card-title fw-bold">Credit</h5>
              <p className="card-text">
                Instant credit limit upto Rs. 10 lacs with no cost and zero
                hidden charges
              </p>
            </div>
            <div
              className="card-footer"
              style={{ backgroundColor: "#F76F49" }}
            ></div>
          </div>
        </div>
        <div className="col-md-3 mb-5">
          <div className="card border-0">
            <img
              src="assets/img/prices.png"
              className="img-fluid"
              alt="prices"
              height={70}
              width={70}
            />
            <div className="card-body">
              <h5 className="card-title fw-bold">⁠Low Prices</h5>
              <p className="card-text">
                Buy materials from our partner distributors and manufactures at
                lower prices
              </p>
            </div>
            <div
              className="card-footer"
              style={{ backgroundColor: "#F76F49" }}
            ></div>
          </div>
        </div>
        <div className="col-md-3 mb-5">
          <div className="card border-0">
            <img
              src="assets/img/hasslefree.png"
              className="img-fluid"
              alt="Hasslefree"
              height={70}
              width={70}
            />
            <div className="card-body">
              <h5 className="card-title fw-bold">Hassle - Free</h5>
              <p className="card-text">
                Next day hassle free delivery from partners with no cost / order
                on app
              </p>
            </div>
            <div
              className="card-footer"
              style={{ backgroundColor: "#F76F49" }}
            ></div>
          </div>
        </div>
        <div className="col-md-3 mb-5">
          <div className="card border-0">
            <img
              src="assets/img/order.png"
              className="img-fluid"
              alt="order"
              height={70}
              width={70}
            />
            <div className="card-body">
              <h5 className="card-title fw-bold">Order</h5>
              <p className="card-text">Order at conveninece</p>
            </div>
            <div
              className="card-footer"
              style={{ backgroundColor: "#F76F49" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSection;
