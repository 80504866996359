import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const DeleteDataPolicy = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const supportRequest = "DeleteUser";
      const subject = "Thank you for contacting at Daqi Platform";
      const message = "Delete Data Policy";

      const data = { ...formData, subject, supportRequest, message };
      console.log(data);
      await axios.post("https://api.daqi.in/api/website/SendMail", data);
      await axios.post(
        "https://api.daqi.in/api/website/InsertSupportData",
        data
      );
      Swal.fire({
        icon: "success",
        text: "Mail sent successfully!",
      });
      setFormData({
        name: "",
        email: "",
        contact: "",
      });
    } catch (error) {
      console.error("Error sending message:", error);
      Swal.fire({
        icon: "error",
        title: "Failed to send message. Please try again later.",
      });
    }
  };
  return (
    <>
      <section className="deleteYourDataPolicy">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h4 style={{ fontWeight: "600" }}>
                Request for Account Deletion
              </h4>
              <p>
                Send a request to delete your account Personal Identifiable
                Information (PII) that is stored in our system. You will receive
                an email to verify your request. Once the request is verified we
                will take care of deleting your PII. If you just want to check
                what PII we have stored, you can do email support@daqi.in
              </p>
              <h5>
                Note-: Your request for account deletion will be fulfilled
                within 3 days.
              </h5>
            </div>
            <div className="col-md-6 forImageStyle">
              <img src="assets/img/deleteData.jpg" alt="data" />
            </div>
            <div className="col-md-6">
              <div className="formDataSection">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="formGroupExampleInput">Name</label>
                    <input
                      name="name"
                      type="text"
                      className="form-control"
                      id="formGroupExampleInput"
                      placeholder="Enter your name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlInput1">Email Id</label>
                    <input
                      name="email"
                      type="email"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="name@example.com"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlInput1">
                      Mobile Number
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="mobileNumber"
                      placeholder="Enter mobile number"
                      name="contact"
                      value={formData.contact}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="text-center">
                    <button className="btn btn-customChange">
                      Submit Request
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeleteDataPolicy;
