import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // console.log(formData);
      const supportRequest = "supportData";
      const subject = "Thank you for contacting at Daqi Platform";

      const data = { ...formData, subject, supportRequest };

      await axios.post("https://api.daqi.in/api/website/SendMail", data);
      await axios.post(
        "https://api.daqi.in/api/website/InsertSupportData",
        data
      );

      Swal.fire({
        icon: "success",
        title: "Mail sent successfully!",
      });
      setFormData({
        name: "",
        email: "",
        contact: "",
        message: "",
        subject: "",
      });
    } catch (error) {
      console.error("Error sending message:", error);
      Swal.fire({
        icon: "error",
        text: "Failed to send message. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="fluid-container">
        <div className="image-row">
          <h2>WE’LL BE GLAD TO HELP</h2>
          <img
            src="assets/img/contact_page/contact_slider.png"
            alt="contact"
            className="image contact_banner"
          />
          <img
            src="assets/img/contact_page/mobile_image_banner.png"
            alt="contact"
            className="image mobile_image_banner"
          />
        </div>
      </div>
      <div className="row contact-row">
        <div className="col-md-6 getintouch">
          <h4 className="fw-bold">Get In Touch With Us</h4>
          <div className="row contactSection">
            <div className="contact-box col-md-3">
              <img
                src="assets/img/contact_page/location_icon.png"
                alt="location_icon"
              />
            </div>
            <div className="col-md-5">
              <p className="conatct-info-row">
                Our Location <br />
                <span>Vashi, Navi Mumbai, India</span>
              </p>
            </div>
          </div>
          <div className="row contactSection">
            <div className="contact-box col-md-3">
              <img
                src="assets/img/contact_page/telephone.png"
                alt="location_icon"
              />
            </div>
            <div className="col-md-5">
              <p className="conatct-info-row">
                Phone Number <br />
                <span>India +91 8109743037</span>
              </p>
            </div>
          </div>
          <div className="row contactSection">
            <div className="contact-box col-md-3">
              <img src="assets/img/contact_page/mail.png" alt="location_icon" />
            </div>
            <div className="col-md-5">
              <p className="conatct-info-row">
                Email Address <br />
                <span>support@daqi.in</span>
              </p>
            </div>
          </div>
          <div className="delete-data-policy">
            <h4>Delete Data Policy</h4>
            <div>
              <Link to="/delete-data-policy">
                <button type="button" className="btn btn-md contact-button2">
                  <b>Click Now</b>
                </button>
              </Link>
            </div>
          </div>
          <img
            className="conatct_logo"
            src="assets/img/contact_page/contact_logo.png"
            alt=""
          />
          <img
            className="contact_image3"
            src="assets/img/contact_page/contact_image3.png"
            alt="topRightImage"
          />
        </div>

        <div className="col-md-6 secondcolumn">
          <img
            src="assets/img/contact_page/topright.png"
            alt="topRightImage"
            className="topRightImage"
          />
          <img
            src="assets/img/contact_page/contact_image.png"
            alt="topRightImage"
            className="topRightImage2"
          />
          <img
            src="assets/img/contact_page/contact_image.png"
            alt="topRightImage"
            className="topLeftImage"
          />

          <div className="contact-box2">
            <form onSubmit={handleSubmit}>
              <div className="form-group-contact">
                <input
                  type="text"
                  id="name"
                  name="name"
                  required
                  placeholder="Your name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group-contact">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group-contact">
                <input
                  type="number"
                  id="contact"
                  name="contact"
                  placeholder="Your Phone"
                  required
                  value={formData.contact}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group-contact">
                <textarea
                  id="message"
                  name="message"
                  placeholder="Your Message"
                  required
                  rows="4"
                  cols="50"
                  value={formData.message}
                  onChange={handleChange}
                />
              </div>
              <button
                type="submit"
                className="btn btn-md contact-button"
                disabled={loading}
              >
                {loading ? "Sending..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe
            title="GoogleMap"
            className="iframe_tag"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=Ptolemy%20technology,%20awfis%20arcade,raghuleela%20mall,%20vashi,%20navi%20mumbai&t=&z=13&ie=UTF8&iwloc=&output=embed"
            style={{ width: "100%", height: "300px" }}
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default Contact;
