import React from "react";

const Section = () => {
  return (
    <section className="text-white py-4 myAppDownloadSecCheck">
      <div className="container text-center mt-4">
        <h2 className="mb-4">Download The Daqi App Now </h2>
        <p className="lead mb-4">
          Use app to order materials on credit, see previous orders, get
          statement of account and see your loan details.{" "}
        </p>
        <a
          href="https://play.google.com/store/apps/details?id=com.daqi.in"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="leadGooglePlay"
            src="assets/img/credit_page/googlePlay.png"
            alt="Get it on Google Play"
          />
        </a>
      </div>
    </section>
  );
};

export default Section;
