import React from "react";

const CancellationAndRefunds = () => {
  return (
    <>
      <div className="cancel_and_refund_page">
        <h3>PTOLEMY TECHNOLOGY PRIVATE LIMITED Refund Policy</h3>
        <p>
          Disclaimer: In the event of any discrepancy or conflict, the English
          version will prevail over the translation.
        </p>
        <p>
          Once we receive your return or the seller notifies us of receipt of
          return, as the case may be, a refund is issued to the original payment
          method (in case of pre-paid transactions) or to your bank account / as
          PTOLEMY TECHNOLOGY Pay balance (in case of Pay on Delivery orders).
        </p>
        <p>
          Refund Timelines: <br /> The refund time period for different modes of
          payments is provided below.
        </p>
        <table className="tableLending">
          <tbody>
            <tr>
              <td width={350}>Available refund method</td>
              <td>Refund Time-frame</td>
            </tr>
            <tr>
              <td width={350}></td>
              <td>
                FBA orders (After the return is received by PTOLEMY TECHNOLOGY)
              </td>
            </tr>
            <tr>
              <td width={350}></td>
              <td>
                Seller-Fulfilled orders (After seller notifies PTOLEMY
                TECHNOLOGY of receipt of return)
              </td>
            </tr>
            <tr>
              <td colspan="2">Prepaid Orders</td>
            </tr>
            <tr>
              <td width={350}>PTOLEMY TECHNOLOGY Pay Balance*</td>
              <td>2 hours</td>
            </tr>
            <tr>
              <td width={350}>Credit Card/ Debit Card</td>
              <td>2-4 Business Days</td>
            </tr>
            <tr>
              <td width={350}>
                Net Banking Account (Credited to Bank Account)
              </td>
              <td>3-5 Business Days</td>
            </tr>
            <tr>
              <td width={350}>UPI Linked Bank Account</td>
              <td>2-4 business days</td>
            </tr>
            <tr>
              <td width={350}>PTOLEMY TECHNOLOGY Pay Later</td>
              <td>5 Business days</td>
            </tr>
            <tr>
              <td colspan="2">Pay on Delivery Orderss</td>
            </tr>
            <tr>
              <td width={350}>NEFT to Bank Account</td>
              <td>3-5 Business Days</td>
            </tr>
            <tr>
              <td width={350}>PTOLEMY TECHNOLOGY Pay Balance*</td>
              <td>2 hours</td>
            </tr>
            <tr>
              <td width={350}>Paper Cheque</td>
              <td>8-10 business days</td>
            </tr>
          </tbody>
        </table>
        <p>
          *PTOLEMY TECHNOLOGY Pay balance is a sum of all balances associated
          with PTOLEMY TECHNOLOGY Gift Cards in your PTOLEMY TECHNOLOGY PRIVATE
          LIMITED account. PTOLEMY TECHNOLOGY Gift Cards are issued Qwikcilver
          Solutions Private Limited and are subject to the PTOLEMY TECHNOLOGY
          Gift Card Terms and Conditions. If the standard time-frame as
          mentioned in the above table has expired and you have still not
          received the refund, please contact your credit or debit card issuer
          or your bank for more information. Refunds will not be processed in
          cash. Refunds can be processed via paper cheque only in exceptional
          cases.
        </p>
        <p>
          Note: International customers would need to reach out to our Customer
          Service team to get refunds for their purchase. The refund would be
          made to the original payment method/instrument used to make the
          initial purchase.
        </p>
        <p>
          For Seller-Fulfilled orders:
          <ol>
            <li>
              For damaged/ defective items, the seller will issue a refund if
              the item cannot be repaired or replaced.
            </li>
            <li>
              In any case where a refund is required, the seller(s) need to
              authorise that refund. PTOLEMY TECHNOLOGY PRIVATE LIMITED can
              assist in facilitating refunds for you only when the seller
              notifies us of the receipt of the item. Once the seller notifies
              us of the receipt of the return item, the above refund time period
              will apply for processing refunds.
            </li>
          </ol>
        </p>
        <p>Refund for Pay on Delivery Orders:</p>
        <p>
          For Pay on Delivery orders, refunds will be processed either to your
          bank account (via National Electronic Funds Transfer (NEFT)) or
          PTOLEMY TECHNOLOGY account (as PTOLEMY TECHNOLOGY Pay balance). If you
          wish to receive the Pay on Delivery order’s refund to your bank
          account, you can update the details of the bank account in Your
          Account section or from the Returns Centre when you are returning an
          item.
        </p>
        <p>
          Note: Refunds cannot be processed to third-party accounts. The name on
          your PTOLEMY TECHNOLOGY account should match with the name of the bank
          account holder.
        </p>
        <p>Paper cheque clearing</p>
        <p>
          All cheque refunds will be in form of "at par" Deutsche Bank cheques.
          All Deutsche Bank cheques are cleared locally in the following cities:
        </p>
        <table border="1" className="cheque-clearing-table" width="50%">
          <tr style={{ backgroundColor: "#cccccc", fontWeight: "bold" }}>
            <td>Ahmedabad</td>
            <td>Delhi</td>
            <td>Ludhiana</td>
            <td>Pune</td>
          </tr>
          <tr>
            <td>Aurangabad</td>
            <td>Gurgaon</td>
            <td>Moradabad</td>
            <td>Salem</td>
          </tr>
          <tr style={{ backgroundColor: "#cccccc", fontWeight: "bold" }}>
            <td>Bangalore</td>
            <td>Kolhapur</td>
            <td>Mumbai</td>
            <td>Surat</td>
          </tr>
          <tr>
            <td>Chennai</td>
            <td>Kolkata</td>
            <td>Noida</td>
            <td>Vellore</td>
          </tr>
        </table>
        <p>
          If you plan to present the cheque in person in any other city, ensure
          that your beneficiary bank sends the cheque for outstation clearing.
        </p>
        <p>
          In case you plan to drop your cheque in a clearance box, please note
          the following:
          <ul>
            <li>
              If you are dropping the cheque in a clearance box in any of the
              above cities, use the box marked Local Cheques.
            </li>
            <li>
              If you are dropping the cheque in a clearance box in any other
              city, use the box marked Outstation Cheques.
            </li>
          </ul>
        </p>
        <p>
          Failing to follow the above instructions might result in the cheque
          not being processed and a penalty being levied by the bank.
        </p>
        <p>
          Note: Once a cheque is issued, PTOLEMY TECHNOLOGY will send you an
          e-mail with the tracking details of the refund cheque within 4
          business days from the date of refund.
        </p>
        <p>
          Shipping Cost Refunds
          <ol>
            <li>
              For Fulfilled by PTOLEMY TECHNOLOGY and Prime Eligible items,
              return shipping costs of up to Rs. 100 will be refunded. The cost
              of gift-wrapping will also be refunded, if any. All such refunds
              will be issued through cheques.
            </li>
            <li>
              If you incur return shipping charges over Rs. 100 for returning
              large and heavy items, you can contact us for an additional
              refund. For refund of such additional charges, proof of payment,
              like a courier receipt needs to be submitted.
            </li>
            <li>
              If you're returning a Seller-Fulfilled item, you can request the
              seller to reimburse the return shipping charges you incurred. In
              such cases, a seller might ask you to provide the courier receipt.
            </li>
          </ol>
        </p>
        <p>
          Note: The seller will not refund the shipping cost incurred in case of
          remorse returns.
        </p>
        <p>Cancellations & Charges</p>
        <p>
          You can track the status of your order in my orders section. Order
          cancellation and address modification will be allowed based on the
          order status. For COD orders online payment can be made as per the
          availability of the option.
        </p>
        <p>Link to Orders page</p>
        <p>Extra charges, Convenience fee</p>
        <p>
          A Convenience Fee of Rs. 79/- along with Platform fee Rs.20/- will be
          applicable to the orders under Rs.1199/-, and Rs.19/-Convenience Fee
          along with Rs.20/- Platform Fee will be applicable for the orders with
          only personal care items under 299/-.
        </p>
        <p>Click here for complete information.</p>
        <p>What is PTOLEMY TECHNOLOGY's Cancellation Policy?</p>
        <p>
          You can now cancel an order when it is in packed/shipped status, as
          long as the cancel option is available on the App/Website. This
          includes items purchased on sale also. Any amount paid will be
          credited into the same payment mode using which the payment was made
          or you can opt for a refund.
        </p>
        <p>
          Refund timelines for Cancellation:
          <ul>
            <li>
              For Bank Account: 1-3 business days after the item reaches us.
            </li>
            <li>
              For Online Refund: 7-10 business days after the item reaches us.
            </li>
          </ul>
        </p>
      </div>
    </>
  );
};

export default CancellationAndRefunds;
