import React from "react";
import FirstSection from "./FirstSection";
// import SecondSection from "./SecondSection";
import ProductSection from "./ProductSection";
import FourthSection from "./FourthSection";
import DemoPage from "./DemoPage";

const CreditPage = () => {
  return (
    <>
      <div className="credit_page">
        <FirstSection />
        {/* <SecondSection /> */}
        <DemoPage />
        <ProductSection />
        <FourthSection />
      </div>
    </>
  );
};

export default CreditPage;
