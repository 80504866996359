import React from "react";

const ShippingPolicy = () => {
  return (
    <>
      <div className="grievance-page">
        <h3 style={{ textAlign: "center", fontWeight: "700" }}>
          Shipping Policy
        </h3>
        <p>At Daqi, we are committed to providing our business customers with a seamless purchasing experience. Our shipping policy outlines the terms and conditions regarding the delivery of products purchased through our platform.</p>
        
        <div>
          <h5 style={{fontWeight:700}}>1. Shipping Methods</h5>
          <p>We offer a variety of shipping methods to meet the needs of our business customers, including:</p>
            <ul>
                <li>Standard Shipping: 5-7 business days</li>
                <li>Expedited Shipping: 2-3 business days</li>
                <li>Same-Day Delivery: Available in select areas for urgent orders</li>
            </ul>
        </div>
        <div>
          <h5 style={{fontWeight:700}}>2. Shipping Costs</h5>
          <p>Shipping costs are calculated at checkout based on the following factors:</p>
          <ul>
            <li>Weight and dimensions of the products</li>
            <li>Delivery location</li>
            <li>Selected shipping method</li>
          </ul>
          <p>For bulk orders, please contact our sales team for customized shipping rates.</p>
        </div>

        <div>
          <h5 style={{fontWeight:700}}>3. Order Processing Time</h5>
          <p>Orders are typically processed within 1-2 business days. Orders placed on weekends or holidays will be processed on the next business day.</p>
        </div>

        <div>
          <h5 style={{fontWeight:700}}>4. Delivery Locations</h5>
          <p>We ship to verified business addresses within [Country/Region]. For international shipping, please contact our customer service team to discuss options. We will leverage third-party vendors to enhance our shipping capabilities and improve delivery times.</p>
        </div>

        <div>
          <h5 style={{fontWeight:700}}>5. Order Tracking</h5>
          <p>Once your order has been shipped, you will receive a confirmation email with tracking information.</p>
        </div>

        <div>
          <h5 style={{fontWeight:700}}>6. Damaged or Lost Shipments</h5>
          <p>In the event that your shipment is damaged or lost, please contact our customer service team within 5 business days of delivery. We will investigate the issue and work with you to resolve it promptly.</p>
        </div>

        <div>
          <h5 style={{fontWeight:700}}>7. Returns and Exchanges</h5>
          <p>For information on returns and exchanges, please refer to our Returns Policy. Shipping costs for returns may be the responsibility of the buyer unless the return is due to an error on our part.</p>
        </div>

        <div>
          <h5 style={{fontWeight:700}}>8. Changes to Shipping Policy</h5>
          <p>We reserve the right to update or modify this shipping policy at any time. Changes will be posted on our website, and we encourage you to review this policy periodically.</p>
        </div>

        <div>
          <h5 style={{fontWeight:700}}>Contact Us</h5>
          <p>If you have any questions or concerns regarding our shipping policy, please contact our customer service team at +91-8109743037.</p>
          <p>Thank you for choosing Daqi as your trusted FMEG marketplace partner!</p>
        </div>
      </div>
    </>
  );
};

export default ShippingPolicy;
