import React from "react";

const Calltoaction = () => {
  return (
    <div className="container">
      <div className="row">
        {/* First Column */}
        <div className="col-md-7 catlongSectionFirst">
          <div className="d-flex flex-column justify-content-center">
            <h1 className="topHeadingFisConstru">
              <span className="buyFmegFirstSpan">
                Buy FMEG and building construction materials
              </span>
              <span className="buyFmegSecondSpan"> on credit with Daqi</span>
            </h1>
            <div className="img-fluid-only-mobile">
              <img src="assets/img/calltoaction.png" alt="caltoaction" />
            </div>
            <p className="withEmbeddedPara">
              With embedded credit, the platform integrates
              <br /> merchants/shops and manufacturers/suppliers for
              <br /> key building/electrical materials at better price
              <br /> and convenience.{" "}
              <span style={{ color: "#fc5952" }}>Learn more &#8594;</span>
              <i
                className="fas fa-arrow-right"
                style={{ marginLeft: "10px", color: "#fc5952" }}
              ></i>
            </p>
            {/* <div className="learn-more" style={{marginTop: '20px'}}>
            <span style={{color: '#fc5952'}}>Learn more</span>
            <i className="fas fa-arrow-right" style={{marginLeft: '10px', color: '#fc5952'}}></i>
          </div> */}
            <div className="mb-3 startAndGoogleIcon">
              <button className="btn btn-md learnMoreChangebtn">
                Get Started
              </button>
              <a
                href="https://play.google.com/store/apps/details?id=com.daqi.in"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="assets/img/credit_page/googlePlay.png"
                  alt="Get it on Google Play"
                />
              </a>
            </div>
          </div>
        </div>
        {/* Second Column */}
        <div className="col-md-5 mb-4 mangMarginAndAuto">
          <img
            src="assets/img/calltoaction.png"
            alt="caltoaction"
            className="img-fluid mobileWithResponceall forMobileDisplayNone"
          />
        </div>
      </div>
    </div>
  );
};

export default Calltoaction;
