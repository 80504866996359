import React,{useEffect, useState} from "react";
import Calltoaction from "./Calltoaction";
import Tradingsection from "./Tradingsection";
import ThirdSection from "./ThirdSection";
import Platform from "./Platform";
import WorkFlow from "./WorkFlow";
import CardSection from "./CardSection";
import Section from "./Section";
import BrandsSection from "./BrandsSection";
import Testimonial from "./Testimonial";
import Popup from "./Popup";

const HomePage = () => {
  return (
    <>
  
      <Calltoaction />
      <Tradingsection />
      <ThirdSection />
      <CardSection />
      <Platform />
      <WorkFlow />
      <Testimonial />
      <Section />
      <BrandsSection />
      
    </>
  );
};

export default HomePage;
