import React from "react";

const Tradingsection = () => {
  return (
    <div className="container mngTopBottomSpace">
      {/* First Row */}
      <div className="row mb-4 justify-content-center">
        <div className="col text-center">
          <h1>
            <span> Start trading in 3 simple steps</span>
          </h1>
          <p>
            Empower Your Purchases: Trade Smarter With Daqi's Interest-Free
            Credit
          </p>
        </div>
      </div>
      <div className="row justify-content-center mt-4 mb-4">
        <div className="col text-center">
          <img
            src="assets/img/tradesection2.png"
            alt="trade"
            className="img-fluid forMobileDisplayNone"
            width={1000}
          />
          <img
            src="assets/img/stepMobile2.png"
            alt="trade"
            className="img_fluid_mobilImage forDeskTopDisplayNone"
            width={1000}
          />
        </div>
      </div>
    </div>
  );
};

export default Tradingsection;
