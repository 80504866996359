import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import HomePage from "./Components/Home/HomePage";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy";
import TermsAndConditions from "./Components/Pages/TermsAndConditions";
import CancellationAndRefunds from "./Components/Pages/CancellationAndRefunds";
import LandingPrivacy from "./Components/Pages/LandingPrivacy";
import GrievanceRedressal from "./Components/Pages/GrievanceRedressal";
import FAQ from "./Components/Pages/FAQ";
import AboutUs from "./Components/Pages/AboutUs";
import CreditPage from "./Components/CreditPage/CreditPage";
import ShippingPolicy from "./Components/Pages/ShippingPolicy";
import DeleteDataPolicy from "./Components/Pages/DeleteDataPolicy";
import Contact from "./Components/Contact/Contact";
import LayoutWithoutHeaderFooter from "./LayoutWithoutHeaderFooter";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <HomePage />
            </Layout>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Layout>
              <PrivacyPolicy />
            </Layout>
          }
        />
        <Route
          path="/shipping-policy"
          element={
            <Layout>
              <ShippingPolicy />
            </Layout>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <Layout>
              <TermsAndConditions /> 
            </Layout>
          }
        />
        <Route
          path="/cancellation-and-refunds"
          element={
            <Layout>
              <CancellationAndRefunds />
            </Layout>
          }
        />
        <Route
          path="/landing-privacy-policy"
          element={
            <Layout>
              <LandingPrivacy />
            </Layout>
          }
        />
        <Route
          path="/grievance-redressal"
          element={
            <Layout>
              <GrievanceRedressal />
            </Layout>
          }
        />
        <Route
          path="/buy-products-on-credit"
          element={
            <Layout>
              <CreditPage />
            </Layout>
          }
        />
        <Route
          path="/delete-data-policy"
          element={
            <Layout>
              <DeleteDataPolicy />
            </Layout>
          }
        />
        <Route
          path="/contact-us"
          element={
            <Layout>
              <Contact />
            </Layout>
          }
        />
        <Route
          path="/FAQ's"
          element={
            <Layout>
              <FAQ />
            </Layout>
          }
        />
        <Route
          path="/AboutUs"
          element={
            <Layout>
              <AboutUs />
            </Layout>
          }
        />
        <Route
          path="/AboutUs_MobileApp"
          element={
            <LayoutWithoutHeaderFooter>
              <AboutUs />
              </LayoutWithoutHeaderFooter>
          }
        />
        <Route
          path="/FAQ's_MobileApp"
          element={
            <LayoutWithoutHeaderFooter>
             <FAQ />
              </LayoutWithoutHeaderFooter>
          }
        />
        {/* Route without header and footer */}
        <Route
          path="/privacyPolicy_MobileApp"
          element={
            <LayoutWithoutHeaderFooter>
              <PrivacyPolicy />
            </LayoutWithoutHeaderFooter>
          }
        />
        <Route
          path="/termsAndConditions_MobileApp"
          element={
            <LayoutWithoutHeaderFooter>
              <TermsAndConditions />
            </LayoutWithoutHeaderFooter>
          }
        />
        <Route
          path="/contact-us_MobileApp"
          element={
            <LayoutWithoutHeaderFooter>
              <Contact />
            </LayoutWithoutHeaderFooter>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
