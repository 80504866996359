import React from "react";

const Platform = () => {
  return (
    <section className="platformSection">
      <div className="container">
        <div className="row mb-4">
          <div className="col colItemChange text-center">
            <h1>
              <span>One Platform, Multiple Solutions For Enterprises</span>
            </h1>
            <p
              style={{ color: "#FC5952", fontWeight: "600", fontSize: "17px" }}
            >
              Enhance Your Sales And Inventory With Daqi
            </p>
          </div>
        </div>
        <div className="row charngeDashImage">
          {/* <div className="col-md-4">
            <div className="dashboardImage">
              <img
                src="assets/img/dashboard.png"
                alt="dashboard"
                className="img-fluid"
              />
            </div>
          </div> */}
          <div className="col-md-4 col-lg-6 changesonMarPading">
            <div className="card mb-3 border-0 shadow">
              <img
                src="assets/img/Layer4.png"
                height={70}
                width={70}
                alt="Easy Financing"
                className="img-fluid"
                style={{
                  marginTop: "25px",
                  padding: "10px",
                  marginLeft: "12px",
                }}
              />
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{
                    color: "#FC5952",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  Embedded finance for merchants at the time of onboarding
                </h5>
                <p className="card-text">
                  Enhance sales and outreach with our platform
                </p>
              </div>
            </div>
            <div className="card border-0 shadow">
              <img
                src="assets/img/Layer1.png"
                alt="Layer1"
                height={70}
                width={70}
                className="img-fluid"
                style={{
                  marginTop: "25px",
                  padding: "10px",
                  marginLeft: "12px",
                }}
              />
              <div className="card-body">
                <h5 className="card-title fw-bold platform-title">
                  APIs provide relevant, accurate, and timely information to the
                  lender
                </h5>
                <p className="card-text">Easy to onboard multiple partners</p>
              </div>
            </div>
          </div>

          <div
            className="col-md-4 col-lg-6"
            style={{ marginBottom: "50px", marginTop: "20px" }}
          >
            <div className="card mb-3 border-0 shadow">
              <img
                src="assets/img/Layer2.png"
                height={70}
                width={70}
                alt="Layer2"
                className="img-fluid"
                style={{
                  marginTop: "25px",
                  padding: "10px",
                  marginLeft: "12px",
                }}
              />
              <div className="card-body">
                <h5 className="card-title fw-bold platform-title">
                  Deep distribution network across tier 1, 2, 3 and 4 centres
                  across the country
                </h5>
                <p className="card-text">last mile connectivity in FMEG</p>
              </div>
            </div>
            <div className="card border-0 shadow">
              <img
                src="assets/img/Layer3.png"
                alt="Layer3"
                className="img-fluid"
                height={70}
                width={70}
                style={{
                  marginTop: "25px",
                  padding: "10px",
                  marginLeft: "12px",
                }}
              />
              <div className="card-body">
                <h5 className="card-title fw-bold platform-title">
                  MSME(Micro and Small enterprises) product focus (Priority
                  Sector Lending)
                </h5>
                <p className="card-text">
                  Product level differentiation at platform
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          class="row"
          style={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <div class="platformcolumn">
            <div class="platformcard">
              <div className="card mb-3 border-0 shadow">
                <img
                  src="assets/img/Layer4.png"
                  height={70}
                  width={70}
                  alt="Easy Financing"
                  className="img-fluid"
                  style={{
                    marginTop: "25px",
                    padding: "10px",
                    marginLeft: "12px",
                  }}
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{
                      color: "#FC5952",
                      fontWeight: "600",
                      fontSize: "20px",
                    }}
                  >
                    Embedded finance for merchants at the time of onboarding
                  </h5>
                  <p className="card-text">
                    Enhance sales and outreach with our platform
                  </p>
                </div>
              </div>
            </div>
            <div class="platformcard">
              <div className="card border-0 mb-3">
                <img
                  src="assets/img/Layer1.png"
                  alt="Layer1"
                  height={70}
                  width={70}
                  className="img-fluid"
                  style={{
                    marginTop: "25px",
                    padding: "10px",
                    marginLeft: "12px",
                  }}
                />
                <div className="card-body">
                  <h5 className="card-title fw-bold">
                    APIs provide relevant & accurate, info to the lender
                  </h5>
                  <p className="card-text">Easy to onboard multiple partners</p>
                </div>
              </div>
            </div>
          </div>
          <div class="platformcolumn">
            <div class="platformcard">
              <div className="card mb-3 border-0">
                <img
                  src="assets/img/Layer2.png"
                  height={70}
                  width={70}
                  alt="Layer2"
                  className="img-fluid"
                  style={{
                    marginTop: "25px",
                    padding: "10px",
                    marginLeft: "12px",
                  }}
                />
                <div className="card-body">
                  <h5 className="card-title fw-bold">
                    Deep distribution network across tier 1, 2, 3 and 4 centres
                  </h5>
                  <p className="card-text">last mile connectivity in FMEG</p>
                </div>
              </div>
            </div>
            <div class="platformcard">
              <div className="card border-0">
                <img
                  src="assets/img/Layer3.png"
                  alt="Layer3"
                  className="img-fluid"
                  height={70}
                  width={70}
                  style={{
                    marginTop: "25px",
                    padding: "10px",
                    marginLeft: "12px",
                  }}
                />
                <div className="card-body">
                  <h5 className="card-title fw-bold">
                    MSME product focus (Priority Sector Lending)
                  </h5>
                  <p className="card-text">
                    Product level differentiation at platform
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Platform;
